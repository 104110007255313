import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const StarOutlinedIcon = ({ fill = 'transparent', ...props }) => {
  return (
    <SvgIcon width="33" height="33" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.1854 3.01508L21.8987 8.44175C22.2687 9.19716 23.2554 9.92175 24.0879 10.0605L29.0058 10.8776C32.1508 11.4017 32.8908 13.6834 30.6245 15.9342L26.8012 19.7576C26.1537 20.4051 25.7991 21.6538 25.9995 22.548L27.0941 27.2809C27.9575 31.0272 25.9687 32.4763 22.6541 30.5184L18.0445 27.7897C17.212 27.2963 15.84 27.2963 14.992 27.7897L10.3825 30.5184C7.08329 32.4763 5.07913 31.0117 5.94246 27.2809L7.03704 22.548C7.23746 21.6538 6.88288 20.4051 6.23538 19.7576L2.41204 15.9342C0.161209 13.6834 0.885793 11.4017 4.03079 10.8776L8.94871 10.0605C9.76579 9.92175 10.7525 9.19716 11.1225 8.44175L13.8358 3.01508C15.3158 0.0704948 17.7208 0.0704948 19.1854 3.01508Z"
        stroke="currentColor"
        fill={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
export default StarOutlinedIcon;
