import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const CheckedHeartIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9999 17.2004C21.9999 18.1004 21.7499 18.9503 21.2999 19.6703C20.4699 21.0603 18.9499 22.0004 17.1999 22.0004C15.4499 22.0004 13.9199 21.0603 13.0999 19.6703C12.6599 18.9503 12.3999 18.1004 12.3999 17.2004C12.3999 14.5504 14.5499 12.4004 17.1999 12.4004C19.8499 12.4004 21.9999 14.5504 21.9999 17.2004Z"
        stroke="#2D264B"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M15.3298 17.2005L16.5098 18.3805L19.0698 16.0205" stroke="#2D264B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M22 8.68964C22 10.6596 21.49 12.3996 20.69 13.9096C19.81 12.9796 18.57 12.3997 17.2 12.3997C14.55 12.3997 12.4 14.5496 12.4 17.1996C12.4 18.4296 12.87 19.5497 13.63 20.3997C13.26 20.5697 12.92 20.7096 12.62 20.8096C12.28 20.9296 11.72 20.9296 11.38 20.8096C8.48 19.8196 2 15.6896 2 8.68964C2 5.59964 4.49 3.09961 7.56 3.09961C9.37 3.09961 10.99 3.97965 12 5.32965C13.01 3.97965 14.63 3.09961 16.44 3.09961C19.51 3.09961 22 5.59964 22 8.68964Z"
        stroke="#2D264B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default CheckedHeartIcon;
