import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { getStoredState } from 'redux-persist-immutable';
import { Provider } from 'react-redux';
import configureStore from './store/store';
import ThemeContext from './context/ThemeContext';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { createBrowserHistory } from 'history';
import theme from './jsx/theme';

const render = async (messages) => {
  let res = await getStoredState();

  if (res && typeof res.toJS === 'function') {
    res = res.toJS();
  }

  const { store, persistor } = configureStore(res, createBrowserHistory());

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        {/* <ConnectedRouter history={createBrowserHistory()}> */}
        <BrowserRouter basename="/">
          <StyledEngineProvider injectFirst>
            <ThemeContext>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </ThemeContext>
          </StyledEngineProvider>
        </BrowserRouter>
        {/* </ConnectedRouter> */}
      </Provider>
    </React.StrictMode>
  );
};

render();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
