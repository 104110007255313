export const INIT_NETWORK_ACTION = '[network action] init network';
export const ADD_NETWORK_ACTION = '[network action] add network';
export const REMOVE_NETWORK_ACTION = '[network action] remove network';

export function initNetworkAction() {
  return {
    type: INIT_NETWORK_ACTION,
    payload: null
  };
}

export function addNetworkAction(url) {
  return {
    type: ADD_NETWORK_ACTION,
    payload: url
  };
}

export function removeNetworkAction(url) {
  return {
    type: REMOVE_NETWORK_ACTION,
    payload: url
  };
}