import React from 'react';
import { Card, CardContent, CardMedia, CardActions, ListItem, Grid, Box, Typography, CardActionArea, SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RatingListItem from '../List/RatingListItem';
import PriceListItem from '../List/PriceListItem';
import LocationIcon from '../Icon/LocationIcon';

const RestaurantCard = ({ data, index }) => {
  const navigate = useNavigate();
  
  return (
    <Card 
      key={index}
      variant="outlined"
      sx={{ backgroundColor: '#FFF', borderRadius: '10px', width: '100%', height: '100%' }}
      onClick={() => navigate(`/restaurant/${data.id}`, { state: data })}
    >
      <CardActionArea>
        <CardMedia component="img" src={data?.image || require('../../../images/resturent-review/yogaimage.png')} sx={{ height: 170 }} />
        <CardContent sx={{paddingBottom: '8px'}}>
          <Typography gutterBottom noWrap>
            {data?.name}
          </Typography>
          <ListItem disablePadding alignItems="flex-start" sx={{ color: 'grey.700' }}>
            <LocationIcon sx={{ fontSize: '1.25rem' }} />
            <Typography variant="body2" color="inherit" 
              sx={{
                marginLeft: '5px',
                display: '-webkit-box',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
              }}
            >
              {data?.address}
            </Typography>
          </ListItem>
        </CardContent>
        <CardActions sx={{paddingTop: '0px', paddingLeft: '16px', paddingRight: '16px', paddingBottom: '10px'}}>
          <Grid
            spacing={0}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{width: '100%'}}
          >
            <Grid item xs={6}  sx={{display: 'flex', justifyContent: 'flex-start'}}>
              <PriceListItem data={data?.average_price_symbol} color="#f29126"/>
            </Grid>
            <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}} >
              <RatingListItem data={data?.rating} color="#f29126"/>
            </Grid>
          </Grid>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};
export default RestaurantCard;
