import React from 'react';
import { Card, CardContent, CardMedia, ListItem, ListItemIcon, Box, ListItemText, Typography, Chip, CardActionArea, AvatarGroup } from '@mui/material';
import StarIcon from '../Icon/StarIcon';
import CalendarIcon from '../Icon/CalendarIcon';
import { formatDate } from '../../../utils/dateTimeUtils';
import LocationIcon from '../Icon/LocationIcon';
import UserAvatarGroup from '../Avatar/UserAvatarGroup';
import RestaurantInformationCardContent from './RestaurantInformationCardContent';
const EventCard = ({ data, index, onClick }) => {
  return (
    <Card variant="outlined" sx={{ backgroundColor: '#FFF', borderRadius: '10px' }} key={index}>
      <CardActionArea onClick={onClick}>
        <Box sx={{ position: 'relative' }}>
          <CardMedia component="img" src={data?.restaurant?.image || require('../../../images/resturent-review/yogaimage.png')} sx={{ height: 170 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'absolute',
              bottom: 0,
              p: 1,
              width: 1 / 1,
            }}
          >
            <Chip
              label={formatDate(data?.executed_at)}
              icon={<CalendarIcon sx={{ color: 'grey.700' }} />}
              sx={{
                color: 'text.primary',
                width: 'fit-content',
                bgcolor: '#FFF',
              }}
              size="small"
            />
            <ListItem disablePadding sx={{ color: '#FFF', width: 'fit-content' }}>
              <ListItemIcon sx={{ minWidth: 'auto' }}>
                <StarIcon rating={data?.restaurant?.rating} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'subtitle2', color: 'inherit' }} primary={data?.restaurant?.rating || 0} sx={{ my: 0 }} />
            </ListItem>
          </Box>
        </Box>
        <RestaurantInformationCardContent data={data?.restaurant} />
        <CardContent sx={{ minHeight: 44, pt: 0 }}>{data?.group?.members.length > 0 && <UserAvatarGroup size="small" data={data?.group?.members} />}</CardContent>
      </CardActionArea>
    </Card>
  );
};
export default EventCard;
