import React, { useState, useEffect } from 'react';
import { DialogActions, Dialog, Button, DialogTitle, DialogContent, FormHelperText } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const JoinGroupDialog = ({ open, onClose, onSubmit }) => {
  const formik = useFormik({
    initialValues: { code: '' },
    validationSchema: Yup.object({
      code: Yup.string().length(6).required('Required'),
    }),
    onSubmit,
  });
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{'Join group'}</DialogTitle>
      <DialogContent>
        <MuiOtpInput length={6} value={formik.values.code} onBlur={formik.handleBlur('code')} onChange={(value) => formik.setFieldValue('code', value)} />
        <FormHelperText error={formik.touched.code && formik.errors.code}>{formik.touched.code && formik.errors.code}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={formik.handleSubmit}>
          {'Join'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JoinGroupDialog;
