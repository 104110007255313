import React from 'react';
import { Card, Divider, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReviewListItem from '../List/ReviewListItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RestaurantListItem from '../List/RestaurantListItem';

const ReviewCard = ({ data, index }) => {
  const navigate = useNavigate();
  return (
    <Card variant="outlined" sx={{ backgroundColor: '#FFF', borderRadius: '10px', height: '100%', display: 'flex', flexDirection: 'column' }} key={index}>
      <ReviewListItem data={data} sx={{ flex: 1 }} />
      <Divider variant="inset" />
      <RestaurantListItem button={false} data={data?.restaurant}>
        <Button endIcon={<ArrowForwardIosIcon />} onClick={() => navigate(`/restaurant/${data?.restaurant?.id}`, { state: data })}>
          {'View'}
        </Button>
      </RestaurantListItem>
    </Card>
  );
};
export default ReviewCard;
