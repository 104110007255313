// import React from 'react';
//import { useNavigate } from "react-router-dom";
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from '../../services/AuthService';
import { removeLocalToken, setLocalToken } from '../../services/localStorage';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const USER_CONFIRMED_ACTION = '[user action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const UPDATE_AUTH = '[update action] update auth';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(data, navigate) {
  return (dispatch) => {
    signUp(data)
      .then((response) => {
        runLogoutTimer(
          dispatch,
          response.data.expiresIn * 1000,
          //history,
        );
        // dispatch(confirmedSignupAction(response.data));
        // navigate('/login');
        //history.push('/dashboard');
      })
      .catch((error) => {
        var errorMessage = formatError(error.response.status);
        if (!errorMessage) {
          errorMessage = error.response.data.detail;
        }
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  removeLocalToken();
  navigate('/login');
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        setLocalToken(`${response.data.token_type} ${response.data.access_token}`);
        runLogoutTimer(
          dispatch,
          1000000,
          // response.data.expiresIn * 1000,
          navigate,
        );
        dispatch(loginConfirmedAction(response.data));
        //console.log(kk);
        //console.log(response.data);
        //console.log('kk------2');
        //return response.data;
        //return 'success';
        //history.push('/dashboard');                
        navigate('/dashboard');
      })
      .catch((error) => {
        //console.log('error');
        console.log('error.response.data===',error.response.data);
        // const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(error.response?.data?.detail));
      });
  };
}

export function logoutAction(data) {
  return {
    type: LOGOUT_ACTION,
    payload: data,
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function userConfirmedAction(data) {
  return {
    type: USER_CONFIRMED_ACTION,
    payload: data,
  };
}


export function updateAction(data) {
  return {
    type: UPDATE_AUTH,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}