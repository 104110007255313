import React, { useState, useEffect } from 'react';
import { Typography, Stack, List, Button, Snackbar } from '@mui/material';
import { isArray } from 'lodash';
import AddReviewDialog from '../../Dialog/AddReviewDialog';
import { apiCreateReview } from '../../../../services/ReviewService';
import { useSelector } from 'react-redux';
import { apiGetRestaurantReviews } from '../../../../services/RestaurantsService';
import ReviewListItem from '../../List/ReviewListItem';

const Reviews = ({ onUpdate, restaurant }) => {
  const auth = useSelector((state) => state.auth?.auth);
  const [addReviewDialogVisible, setAddReviewDialogVisible] = useState(false);
  const [reviews, setReviews] = useState(restaurant.reviews);
  const handleSubmitReview = async (data) => {
    setAddReviewDialogVisible(false);
    const resReview = await apiCreateReview({ ...data, author_id: auth?.user?.id, restaurant_id: restaurant.id });
    if (resReview && !resReview.error) {
      setReviews([...reviews, resReview.data]);
    }
  };
  const getReviews = async () => {
    const resReviews = await apiGetRestaurantReviews(restaurant.id);
    if (resReviews && !resReviews.error) {
      setReviews(resReviews.data);
    }
  };
  useEffect(() => {
    getReviews();
  }, [restaurant.id]);
  return (
    <List
      subheader={
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
          <Typography color="text.secondary">{`Reviews(${reviews?.length || 0})`}</Typography>
          <Button size="large" variant="text" onClick={() => setAddReviewDialogVisible(true)}>
            {'Add a Review'}
          </Button>
        </Stack>
      }
    >
      {isArray(reviews) && reviews.map((review) => <ReviewListItem data={review} sx={{ mb: 1 }} />)}
      <AddReviewDialog open={addReviewDialogVisible} onClose={() => setAddReviewDialogVisible(false)} restaurant={restaurant} onSubmit={handleSubmitReview} />
    </List>
  );
};
export default Reviews;
