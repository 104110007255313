import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Unstable_Grid2 as Grid, Container, Pagination } from '@mui/material';
import { get, split, last } from 'lodash';
import RewardCard from '../Card/RewardCard';
import { apiGetUserNotifications } from '../../../services/UsersService';
import { useSelector } from 'react-redux';
import NotificationListItem from '../List/NotificationListItem';

const MyNotificationList = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth?.auth);
  const [rewards, setRewards] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, totalCount: 0, perPage: 12 });

  const getRewards = async () => {
    const range = [(pagination.currentPage - 1) * pagination.perPage, pagination.currentPage * pagination.perPage - 1];
    const resNotifications = await apiGetUserNotifications(auth?.user?.id, { range });
    if (resNotifications && !resNotifications.error) {
      const totalCount = Number(last(split(get(resNotifications, 'headers.content-range'), '/')) || 0);
      setRewards(resNotifications.data);
      setPagination({ ...pagination, totalCount });
    }
  };

  useEffect(() => {
    getRewards();
  }, [pagination.currentPage]);

  return (
    <Container>
      <Grid container spacing={2}>
        {rewards.map((item) => (
          <Grid xl={2} md={3} sm={4} xs={6}>
            <NotificationListItem data={item} key={item.id} />
          </Grid>
        ))}
      </Grid>
      <Grid component="nav" container justifyContent="center" sx={{ py: 2 }}>
        <Pagination count={Math.ceil(pagination.totalCount / pagination.perPage)} page={pagination.currentPage} onChange={(_, page) => setPagination({ ...pagination, currentPage: page })} />
      </Grid>
    </Container>
  );
};
export default MyNotificationList;
