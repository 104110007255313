import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import StarIcon from '../Icon/StarIcon';

const PriceListItem = ({ data, sx, color, ...props }) => {
  return (
    <ListItem component='span' disablePadding sx={[{ color: color || '#FFF', width: 'fit-content' }, sx]} {...props}>
      <ListItemText primary={data || ''} sx={{ my: 0 }} />
    </ListItem>
  );
};
export default PriceListItem;
