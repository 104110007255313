import React from 'react';
import { ListItem, ListItemIcon, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import LocationIcon from '../Icon/LocationIcon';
import { getInitialName } from '../../../utils/dataUtils';
import RatingListItem from './RatingListItem';

const RestaurantListItem = ({ data, children, showRating, RatingItemProps = {}, ...props }) => {
  return (
    <ListItem {...props}>
      <ListItemAvatar sx={{ mr: 2 }}>
        <Avatar sx={{ width: 100, height: 100 }} src={data?.logo}>
          {getInitialName(data?.name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ color: 'text.secondary', variant: 'h5' }}
        primary={data?.name}
        secondary={
          <ListItem sx={{ color: 'grey.700' }} alignItems="flex-start" disablePadding>
            <ListItemIcon>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: 'inherit', variant: 'h6' }}>{data?.address}</ListItemText>
          </ListItem>
        }
      />
      {showRating && <RatingListItem data={data?.rating} {...RatingItemProps} />}
      {children}
    </ListItem>
  );
};
export default RestaurantListItem;
