import {
  INIT_NETWORK_ACTION,
  ADD_NETWORK_ACTION,
  REMOVE_NETWORK_ACTION,
} from '../actions/NetworkActions';

const initialState = {
  loading: []
};

export function NetworkReducer(state = initialState, action) {
  if (action.type === INIT_NETWORK_ACTION) {
    return {
      loading: []
    };
  }
  if (action.type === ADD_NETWORK_ACTION) {
    var new_state = {...state};
    new_state.loading = [...new_state.loading, action.payload];
    return {
      ...new_state
    };
  }

  if (action.type === REMOVE_NETWORK_ACTION) {
    var new_state = {...state};
    var loading = [...new_state.loading];
    var index = loading.indexOf(action.payload);
    if (index !== -1) {
      loading.splice(index, 1);
    }
    new_state.loading = [...loading];
    return {
      ...new_state
    };
  }
  return state;
}


