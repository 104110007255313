import React, { useState } from 'react';
import { Tab, Container, IconButton } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import TuneOutlinedIcon from '../Icon/TuneOutlinedIcon';
import QuizOutlinedIcon from '../Icon/QuizOutlinedIcon';
import InfoOutlinedIcon from '../Icon/InfoOutlinedIcon';
import SettingIcon from '../Icon/SettingIcon';
import MessageOulinedIocn from '../Icon/MessageOulinedIocn';
import LogoutIcon from '../Icon/LogoutIcon';
import PersonOutlinedIcon from '../Icon/PersonOutlinedIcon';
import { useSelector } from 'react-redux';
import UserListItem from '../List/UserListItem';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { get } from 'lodash';
import FAQPage from './FAQ';
import MyReviewList from './MyReviewList';
import Preference from './Preference';
import Settings from './Settings';
import InviteFriends from './InviteFriends';

const PROFILE_MENUS = [
  { value: 'preferences', label: 'Preferences', icon: <TuneOutlinedIcon />, container: <Preference /> },
  { value: 'setting', label: 'Setting', icon: <SettingIcon />, container:<Settings/> },
  { value: 'help', label: 'Help', icon: <QuizOutlinedIcon /> },
  { value: 'faq', label: 'FAQ', icon: <InfoOutlinedIcon />, container: <FAQPage /> },
  { value: 'my-reviews', label: 'My Reviews', icon: <MessageOulinedIocn />, container: <MyReviewList /> },
  // { value: 'invite-friends', label: 'Invite Friends', icon: <PersonOutlinedIcon />, container: <InviteFriends /> },
  { value: 'logout', label: 'Logout', icon: <LogoutIcon /> },
];

const Profile = () => {
  const routeParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth?.auth);
  const [tabIndex, setTabIndex] = useState(get(PROFILE_MENUS, '0.value'));

  return (
    <Container>
      <Container maxWidth="xs">
        <UserListItem data={auth?.user}>
          <IconButton component={Link} to="/edit-profile" sx={{ border: '1px solid #E6E7EB' }}>
            <BorderColorOutlinedIcon color="primary" />
          </IconButton>
        </UserListItem>
      </Container>
      <TabContext value={tabIndex}>
        <TabList centered sx={{ borderRadius: 1 }} onChange={(_, value) => setTabIndex(value)}>
          {PROFILE_MENUS.map((menu) => (
            <Tab {...menu} iconPosition="start" />
          ))}
        </TabList>
        {PROFILE_MENUS.map((menu) => (
          <TabPanel value={menu.value}>{menu.container}</TabPanel>
        ))}
      </TabContext>
    </Container>
  );
};
export default Profile;
