import React from 'react';
import { CardContent, ListItem, Typography } from '@mui/material';
import LocationIcon from '../Icon/LocationIcon';
const RestaurantInformationCardContent = ({ data, index, onClick }) => {
  return (
    <CardContent>
      <Typography gutterBottom noWrap>
        {data?.name}
      </Typography>
      <ListItem disablePadding alignItems="flex-start" sx={{ color: 'grey.700' }}>
        <LocationIcon sx={{ fontSize: '1.25rem' }} />
        <Typography variant="body2" color="inherit" noWrap>
          {data?.address}
        </Typography>
      </ListItem>
    </CardContent>
  );
};
export default RestaurantInformationCardContent;
