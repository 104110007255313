import React, { useState, useEffect } from 'react';
import { Container, Avatar, Card, Unstable_Grid2 as Grid, CardActionArea, AvatarGroup, IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { apiGetGroupsByUserId } from '../../../services/GroupService';
import GroupCard from '../Card/GroupCard';
import JoinGroupDialog from '../Dialog/JoinGroupDialog';
import { apiJoinUserGroup } from '../../../services/UsersService';
import swal from 'sweetalert';

const GroupListPage = () => {
  const navigate = useNavigate();
  const [joinGroupDialogVisible, setJoinGroupDialogVisible] = useState(false);
  const [groups, setGroups] = useState([]);
  const auth = useSelector((state) => state.auth);

  const handleJoinGroup = async ({ code }) => {
    const resGroupMember = await apiJoinUserGroup(auth?.auth?.user?.id, code);
    if (resGroupMember && !resGroupMember.error) {
      setJoinGroupDialogVisible(false);
      swal('Succefully joined');
    } else {
      swal('Failed to join');
    }
  };

  const getGroups = async () => {
    const resGroups = await apiGetGroupsByUserId(auth?.auth?.user?.id, { sort: ['updated_at', 'DESC'] });
    if (resGroups && !resGroups.error) {
      setGroups(resGroups.data);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);
  return (
    <Container>
      <Button onClick={() => setJoinGroupDialogVisible(true)}>{'Join Group'}</Button>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} md={3}>
          <Card sx={{ width: 1 / 1, height: 1 / 1, display: 'flex' }}>
            <CardActionArea component={Link} to="/groups/create" sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar
                sx={{
                  height: 60,
                  width: 60,
                  border: '1.5px solid #FFFFFF',
                  boxShadow: '0px 4px 8px -2px rgba(30, 60, 120, 0.1), 0px 2px 4px -2px rgba(30, 60, 120, 0.06)',
                }}
              >
                <AddIcon />
              </Avatar>
            </CardActionArea>
          </Card>
        </Grid>
        {groups.map((group) => (
          <Grid item xs={6} sm={4} md={3}>
            <GroupCard data={group} onClick={() => navigate(`/groups/${group.id}`, { state: group })} />
          </Grid>
        ))}
      </Grid>
      <JoinGroupDialog open={joinGroupDialogVisible} onSubmit={handleJoinGroup} onClose={() => setJoinGroupDialogVisible(false)} />
    </Container>
  );
};
export default GroupListPage;
