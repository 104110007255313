import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const PointIcon = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <g filter="url(#filter0_d_286_14509)">
        <g filter="url(#filter1_i_286_14509)">
          <circle cx="19.9516" cy="18.9516" r="16.9516" fill="#F29126" />
        </g>
        <circle cx="19.9516" cy="18.9516" r="15.4105" stroke="#FBDD33" fill="transparent" stroke-width="3.0821" />
        <g filter="url(#filter2_d_286_14509)">
          <path
            d="M18.4805 27.2463V23.5314H20.6667C22.9516 23.5314 24.4803 21.9533 24.4803 19.7178C24.4803 17.548 22.968 15.9042 20.6667 15.9042H16.5901V27.2463H18.4805ZM18.4805 17.7124H20.5516C21.8009 17.7124 22.5735 18.5014 22.5735 19.7178C22.5735 20.9342 21.8009 21.7232 20.5516 21.7232H18.4805V17.7124Z"
            fill="#FBDD33"
          />
        </g>
        <g filter="url(#filter3_d_286_14509)">
          <rect x="11.7327" y="10.2633" width="2.05474" height="4.10947" rx="1.02737" transform="rotate(-38.1121 11.7327 10.2633)" fill="#FBDD33" />
          <rect width="2.05474" height="4.10947" rx="1.02737" transform="matrix(-0.786804 -0.617203 -0.617203 0.786804 28.1924 10.4597)" fill="#FBDD33" />
          <rect width="2.05474" height="6.16421" rx="1.02737" transform="matrix(1 0 0 -1 18.946 13.3008)" fill="#FBDD33" />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_286_14509" x="0.488657" y="0.744328" width="38.9258" height="38.9258" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.25567" />
          <feGaussianBlur stdDeviation="1.25567" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_286_14509" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_286_14509" result="shape" />
        </filter>
        <filter id="filter1_i_286_14509" x="3" y="2" width="33.9031" height="38.0125" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4.10947" />
          <feGaussianBlur stdDeviation="2.05474" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_286_14509" />
        </filter>
        <filter id="filter2_d_286_14509" x="14.5354" y="14.8768" width="11.9996" height="15.4516" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.02737" />
          <feGaussianBlur stdDeviation="1.02737" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.36 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_286_14509" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_286_14509" result="shape" />
        </filter>
        <filter id="filter3_d_286_14509" x="10.093" y="6.10923" width="19.7391" height="10.2737" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.02737" />
          <feGaussianBlur stdDeviation="1.02737" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.36 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_286_14509" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_286_14509" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  );
};
export default PointIcon;
