import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const TuneOutlinedIcon = ({ ...props }) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M15.5701 18.4996V14.5996" stroke="currentColor" fill="transparent" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.5701 7.45V5.5" stroke="currentColor" fill="transparent" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M15.57 12.6492C17.0059 12.6492 18.17 11.4852 18.17 10.0492C18.17 8.61328 17.0059 7.44922 15.57 7.44922C14.134 7.44922 12.97 8.61328 12.97 10.0492C12.97 11.4852 14.134 12.6492 15.57 12.6492Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M8.42993 18.5008V16.5508" stroke="currentColor" fill="transparent" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.42993 9.4V5.5" stroke="currentColor" fill="transparent" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M8.43008 16.5496C9.86602 16.5496 11.0301 15.3855 11.0301 13.9496C11.0301 12.5137 9.86602 11.3496 8.43008 11.3496C6.99414 11.3496 5.83008 12.5137 5.83008 13.9496C5.83008 15.3855 6.99414 16.5496 8.43008 16.5496Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
export default TuneOutlinedIcon;
