import React, { useState, useEffect } from 'react';
import { Button, CardActions, TextField, Container, Avatar, Card, CardContent, Stack, IconButton, CircularProgress, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { apiGetUserById, apiUpdateUserById } from '../../../services/UsersService';
import { useFormik } from 'formik';
import { getFullName, getInitialName } from '../../../utils/dataUtils';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import { apiUploadPhoto } from '../../../services/UploadService';
import { restApiSettings } from '../../../config/api';
import { loginConfirmedAction, updateAction } from '../../../store/actions/AuthActions';
import PhoneNumberTextField from '../TextBox/PhoneNumberTextField';

const EditProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth?.auth);
  const [loading, setLoading] = useState(false);
  const [locationInfo, setLocationInfo] = useState(null)

  const handleDrop = async ([file], e) => {
    formik.setFieldValue('image_file', file);
    formik.setFieldValue('image', URL.createObjectURL(file));
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    phone_number: Yup.number().required('Required'),
    zip_code: Yup.string().required('Required'),
  });

  const handleUpdateUser = async (data) => {
    if (data.image_file instanceof File) {
      const resPhoto = await apiUploadPhoto(data.image_file);
      if (resPhoto && !resPhoto.error) {
        delete data.image_file;
        data.image = restApiSettings.backendURL + resPhoto.data;
      }
    }
    const resUser = await apiUpdateUserById(auth?.user?.id, data);
    if (resUser && !resUser.error) {
      dispatch(updateAction(resUser.data));
      formik.setValues(resUser.data);
      navigate(-1);
    }
  };

  const getUserDetail = async () => {
    const resUser = await apiGetUserById(auth?.user?.id);
    if (resUser && !resUser.error) {
      formik.setValues(resUser.data);
    }
  };


  const getUserLocation = async () => {
    console.log('==== getting location...')
    // window.location.reload()
    if (!navigator.geolocation) {
      // Handle case where geolocation is not available
      console.error('Geolocation is not supported by your browser');
      return;
    }
    try {
      // Ask the user to grant permission to access their location
      const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
      if (permissionStatus.state === 'granted') {
        // Get the user's current location using the geolocation API
        navigator.geolocation.getCurrentPosition(async position => {
          console.log('==== position: ', position)
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
    
          // Use the OpenCage Geocoder API to get additional location information
          const apiKey = process.env.REACT_APP_OPENCAGE_API_KEY || '1b5c04ee33f9430783734fc1c389fd1d';
          const response = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`);
          const data = await response.json();
          console.log('==== location data: ', data);
    
          // Extract the relevant location information from the OpenCage Geocoder response
          const { city, state_code, postcode, country } = data.results[0].components;
          const formatted = data.results[0].formatted;
    
          setLocationInfo({
            latitude,
            longitude,
            city,
            state: state_code,
            zip: postcode,
            country,
            address: formatted,
          });
        });
      } else {
        console.error('Permission to access location was denied');
      }
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  }


  useEffect(() => {
    getUserDetail();
    getUserLocation();
  }, []);

  const setZipCode = (zipCode) => {
    formik.setFieldValue('zip_code', zipCode);
  }

  const openAllowLocationPermission = async () => {
    getUserLocation();
  }

  const formik = useFormik({
    initialValues: auth?.user,
    onSubmit: handleUpdateUser,
    validationSchema,
  });

  console.log('==== locationInfo: ', locationInfo);

  return (
    <Container >
      <Card sx={{ p: 2, bgcolor: '#f5f5f5' }}>
        <Stack justifyContent="center" alignItems="center">
          <Stack justifyContent="center" alignItems="center">
            <Avatar
              sx={{
                bgcolor: '#FFD897',
                color: '#986E00',
                width: 130,
                height: 130,
                fontSize: 38,
                linHeight: 1.45,
                fontWeight: 600,
                border: '2.84559px solid #FFFFFF',
                boxShadow: '0px 7.58824px 15.1765px -3.79412px rgba(30, 60, 120, 0.1), 0px 3.79412px 7.58824px -3.79412px rgba(30, 60, 120, 0.06)',
                mb: -4,
              }}
              src={formik.values.image}
            >
              {getInitialName(getFullName(auth?.user))}
            </Avatar>
            <Dropzone onDropAccepted={handleDrop} multiple={false} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <IconButton {...getRootProps()} sx={{ border: '1px solid #E6E7EB', bgcolor: '#FFF', alignSelf: 'flex-end' }}>
                  <input {...getInputProps()} name={name} />
                  <BorderColorOutlinedIcon />
                </IconButton>
              )}
            </Dropzone>
          </Stack>
        </Stack>
        <CardContent>
          <TextField
            id="first_name"
            label="First name"
            InputLabelProps={{ shrink: true }}
            onChange={formik.handleChange('first_name')}
            name="first_name"
            fullWidth
            margin="normal"
            value={formik.values.first_name}
            error={formik.touched.first_name && formik.errors.first_name}
            helperText={formik.touched.first_name && formik.errors.first_name}
          />
          <TextField  
            id="last_name"
            label="Last name"
            InputLabelProps={{ shrink: true }}
            onChange={formik.handleChange('last_name')}
            name="last_name"
            fullWidth
            margin="normal"
            value={formik.values.last_name}
            error={formik.touched.last_name && formik.errors.last_name}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />
          <TextField
            id="email"
            label="Email"
            InputLabelProps={{ shrink: true }}
            onChange={formik.handleChange('email')}
            name="email"
            fullWidth
            margin="normal"
            value={formik.values.email}
            error={formik.touched.email && formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
          />
          <PhoneNumberTextField
            id="phone_number"
            label="Phone Number"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            placeholder="+1 000 000 0000"
            name="phone_number"
            value={formik.values.phone_number}
            onChange={formik.handleChange('phone_number')}
            onBlur={formik.handleBlur('phone_number')}
            error={formik.touched.phone_number && formik.errors.phone_number}
            helperText={formik.touched.phone_number && formik.errors.phone_number}
            sx={{ mb: 2 }} 
          />
          <TextField
            id="zip_code"
            label="Zip Code"
            InputLabelProps={{ shrink: true }}
            onChange={formik.handleChange('zip_code')}
            name="zip_code"
            fullWidth
            margin="normal"
            value={formik.values.zip_code}
            error={formik.touched.zip_code && formik.errors.zip_code}
            helperText={formik.touched.zip_code && formik.errors.zip_code}
          />
          {locationInfo?.zip ? (
            <Alert
              severity="info"
              action={
                <Button color="inherit" size="small" onClick={() => setZipCode(locationInfo?.zip)}>
                  {'Set ZipCode'}
                </Button>
              }
            >
              {`You are located in here now.`}<br/>
              {`Address: ${locationInfo.address}`}<br/>
              {`Zip Code: ${locationInfo.zip}`}<br/>
              {`Lat, Lon: ${locationInfo.latitude}, ${locationInfo.longitude}`}<br/><br/>
              {`If you want to use this location in your profile, please click "Set ZipCode" button.`}
            </Alert>
          ) : (
            <Alert
              severity="warning"
              action={
                <Button color="inherit" size="small" onClick={openAllowLocationPermission}>
                  {'Allow Location'}
                </Button>
              }
            >
              {`To get the your current location, please allow the location permission in your web brower setting.`}<br/>
            </Alert>
          )}
        </CardContent>
        <CardActions>
          <Button startIcon={loading && <CircularProgress />} disabled={loading} variant="contained" onClick={formik.handleSubmit} fullWidth>
            {'Save Changes'}
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};
export default EditProfile;
