import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Unstable_Grid2 as Grid, Container, Chip, Tabs, Tab, Pagination } from '@mui/material';
import { get, split, last } from 'lodash';
import RewardCard from '../Card/RewardCard';
import { apiUpdateActiveRewardById } from '../../../services/ActiveRewardService';
import { useSelector } from 'react-redux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GiftIcon from '../Icon/GiftIcon';
import UsersDialog from '../Dialog/UsersDialog';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import { getFullName, updateElementInArray } from '../../../utils/dataUtils';
import SendIcon from '../Icon/SendIcon';
import { apiGetUserActiveRewards } from '../../../services/UsersService';

const MyRewardList = () => {
  const location = useLocation();
  const searchKey = location?.state?.searchKey;
  const auth = useSelector((state) => state.auth?.auth);
  const [tabIndex, setTabIndex] = useState(0);
  const [rewards, setRewards] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedActiveReward, setSelectedActiveReward] = useState();
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_count: 0,
    per_page: 12,
  });
  const [usersDialogVisible, setUsersDialogVisible] = useState(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);

  const handleChangeTab = (_, value) => {
    setTabIndex(value);
    setPagination({ ...pagination, current_page: 1 });
  };

  const handleSelectReward = (activeReward) => {
    setUsersDialogVisible(true);
    setSelectedActiveReward(activeReward);
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setConfirmDialogVisible(true);
  };

  const handleSendReward = async () => {
    const resReward = await apiUpdateActiveRewardById(selectedActiveReward?.id, { ...selectedActiveReward, transferee_id: selectedUser?.id });
    setUsersDialogVisible(false);
    if (resReward && !resReward.error) {
      setRewards(updateElementInArray(rewards, resReward.data));
    }
  };

  const getRewards = async () => {
    const range = [(pagination.current_page - 1) * pagination.per_page, pagination.current_page * pagination.per_page - 1];
    const resRewards = await apiGetUserActiveRewards(auth?.user?.id, {
      filter: { search_key: searchKey, owner_id: auth?.user?.id, is_redeemed: tabIndex === 1 ? true : false },
      range,
    });
    if (resRewards && !resRewards.error) {
      const total_count = Number(last(split(get(resRewards, 'headers.content-range'), '/')) || 0);
      setRewards(resRewards.data);
      setPagination({ ...pagination, total_count });
    }
  };

  useEffect(() => {
    getRewards();
  }, [tabIndex, searchKey, pagination.current_page]);

  return (
    <Container>
      <Tabs
        TabIndicatorProps={{
          sx: {
            height: '100%',
            borderRadius: 1,
            zIndex: 0,
          },
        }}
        sx={{
          p: 2,
          bgcolor: '#E6E7EB',
          borderRadius: 1,
          mb: 2,
        }}
        centered
        value={tabIndex}
        onChange={handleChangeTab}
      >
        <Tab
          label="Active Rewards"
          value={0}
          sx={{
            color: '#AAABB3',
            zIndex: 10,
            flex: 1,
            '&.Mui-selected': {
              color: '#FFF',
            },
          }}
        />
        <Tab
          label="Redeemed"
          sx={{
            color: '#AAABB3',
            flex: 1,
            zIndex: 10,
            '&.Mui-selected': {
              color: '#FFF',
            },
          }}
          value={1}
        />
      </Tabs>
      <Grid container spacing={2}>
        {rewards.map((item) => (
          <Grid md={3} sm={4} xs={6}>
            <RewardCard
              ActionChip={
                tabIndex === 0 ? (
                  item.transferee_id ? (
                    <Chip label={`Sent to ${getFullName(item.transferee)}`} sx={{ position: 'absolute', top: 10, left: 10, zIndex: 200, bgcolor: '#FFF', color: '#AAABB3' }} icon={<SendIcon />} />
                  ) : (
                    <Chip
                      label="Send to Others"
                      sx={{ position: 'absolute', top: 10, left: 10, zIndex: 200, bgcolor: '#FFF', color: '#2D264B' }}
                      icon={<GiftIcon />}
                      onDelete={() => handleSelectReward(item)}
                      onClick={() => handleSelectReward(item)}
                      deleteIcon={<ArrowForwardIosIcon fontSize="small" sx={{ color: '#2D264B' }} />}
                    />
                  )
                ) : (
                  <Chip label="Redeemed" icon={<CheckCircleOutlineIcon color="success" />} sx={{ color: 'success.main', position: 'absolute', top: 10, left: 10, zIndex: 200, bgcolor: '#FFF' }} />
                )
              }
              data={item.reward}
              key={item.id}
            />
          </Grid>
        ))}
      </Grid>
      <Grid component="nav" container justifyContent="center" sx={{ py: 2 }}>
        <Pagination count={Math.ceil(pagination.total_count / pagination.per_page)} page={pagination.current_page} onChange={(_, page) => setPagination({ ...pagination, current_page: page })} />
      </Grid>
      <UsersDialog onSelectUser={handleSelectUser} open={usersDialogVisible} onClose={() => setUsersDialogVisible(false)} />
      <ConfirmDialog
        open={confirmDialogVisible}
        onConfirm={handleSendReward}
        onClose={() => setConfirmDialogVisible(false)}
        title={`Send reward to ${getFullName(selectedUser)}?`}
        confirmText={'Send'}
        backText={'Back'}
      />
    </Container>
  );
};
export default MyRewardList;
