import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { Autoplay, Pagination } from 'swiper';

import 'swiper/css';

import banerimg1 from './../../../../images/banner-img/pic-1.jpg';
import banerimg2 from './../../../../images/banner-img/pic-3.jpg';
import banerimg3 from './../../../../images/profileimagesetup.png';
import starGroup from './../../../../images/star-group.png';
import { apiGetRewards } from '../../../../services/RewardService';
import CouponCard from '../../Card/CouponCard';

const sliderBlog = [{ image: banerimg1 }, { image: banerimg2 }, { image: banerimg2 }, { image: banerimg2 }, { image: banerimg2 }, { image: banerimg3 }];

const BannerSlider = () => {
  const [rewards, setRewards] = useState([]);

  const getRewards = async () => {
    const resRewards = await apiGetRewards();
    if (resRewards && !resRewards.error) {
      setRewards(resRewards.data);
    }
  };

  useEffect(() => {
    getRewards();
  }, []);
  return (
    <div className="position-relative ">
      {/* <div className="swiper-pagination-banner"></div> */}
      <Swiper
        className="mySwiper-1"
        // speed= {1200}
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          el: '.swiper-pagination-banner',
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay, Pagination]}
      >
        {rewards.map((reward, index) => (
          <SwiperSlide key={index}>
            <CouponCard data={reward} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default BannerSlider;
