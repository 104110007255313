import React from 'react';
import { ListItemIcon, ListItemText, ListItem, List } from '@mui/material';
import { getCurrentOpeningParams } from '../../../../utils/dateTimeUtils';
import LocationIcon from '../../Icon/LocationIcon';
import DocumentOutlinedIcon from '../../Icon/DocumentOutlinedIcon';
import ClockOutlinedIcon from '../../Icon/ClockOutlinedIcon';
import PhoneOutlinedIcon from '../../Icon/PhoneOutlinedIcon';

const Information = ({ data }) => {
  return (
    <List>
      <ListItem sx={{ color: 'primary.main' }}>
        <ListItemIcon>
          <LocationIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: 'inherit', variant: 'h6' }} primary={data.address} />
      </ListItem>
      <ListItem sx={{ color: 'text.secondary' }}>
        <ListItemIcon>
          <DocumentOutlinedIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText primary={data.description} />
      </ListItem>
      <ListItem sx={{ color: 'text.secondary' }}>
        <ListItemIcon>
          <ClockOutlinedIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: 'grey.700' }} primary={getCurrentOpeningParams(data.opening_hours)?.opening_hours} />
      </ListItem>
      <ListItem sx={{ color: 'text.secondary' }}>
        <ListItemIcon>
          <PhoneOutlinedIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText primary={data.telephone} />
      </ListItem>
    </List>
  );
};
export default Information;
