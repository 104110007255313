import React from 'react';
import { SvgIcon } from '@mui/material';

const PhoneOutlinedIcon = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 26 26" {...props}>
      <path
        d="M22.8008 18.8578C22.8008 19.2478 22.7141 19.6487 22.53 20.0387C22.3458 20.4287 22.1075 20.797 21.7933 21.1437C21.2625 21.7287 20.6775 22.1512 20.0166 22.422C19.3666 22.6928 18.6625 22.8337 17.9041 22.8337C16.7991 22.8337 15.6183 22.5737 14.3725 22.0428C13.1266 21.512 11.8808 20.797 10.6458 19.8978C9.39996 18.9878 8.21913 17.9803 7.09246 16.8645C5.97663 15.7378 4.96913 14.557 4.06996 13.322C3.18163 12.087 2.46663 10.852 1.94663 9.62783C1.42663 8.39283 1.16663 7.21199 1.16663 6.08533C1.16663 5.34866 1.29663 4.64449 1.55663 3.99449C1.81663 3.33366 2.22829 2.72699 2.80246 2.18533C3.49579 1.50283 4.25413 1.16699 5.05579 1.16699C5.35913 1.16699 5.66246 1.23199 5.93329 1.36199C6.21496 1.49199 6.46413 1.68699 6.65913 1.96866L9.17246 5.51116C9.36746 5.78199 9.50829 6.03116 9.60579 6.26949C9.70329 6.49699 9.75746 6.72449 9.75746 6.93033C9.75746 7.19033 9.68163 7.45033 9.52996 7.69949C9.38913 7.94866 9.18329 8.20866 8.92329 8.46866L8.09996 9.32449C7.98079 9.44366 7.92663 9.58449 7.92663 9.75783C7.92663 9.84449 7.93746 9.92033 7.95913 10.007C7.99163 10.0937 8.02413 10.1587 8.04579 10.2237C8.24079 10.5812 8.57663 11.047 9.05329 11.6103C9.54079 12.1737 10.0608 12.7478 10.6241 13.322C11.2091 13.8962 11.7725 14.427 12.3466 14.9145C12.91 15.3912 13.3758 15.7162 13.7441 15.9112C13.7983 15.9328 13.8633 15.9653 13.9391 15.9978C14.0258 16.0303 14.1125 16.0412 14.21 16.0412C14.3941 16.0412 14.535 15.9762 14.6541 15.857L15.4775 15.0445C15.7483 14.7737 16.0083 14.5678 16.2575 14.4378C16.5066 14.2862 16.7558 14.2103 17.0266 14.2103C17.2325 14.2103 17.4491 14.2537 17.6875 14.3512C17.9258 14.4487 18.175 14.5895 18.4458 14.7737L22.0316 17.3195C22.3133 17.5145 22.5083 17.742 22.6275 18.0128C22.7358 18.2837 22.8008 18.5545 22.8008 18.8578Z"
        stroke="currentcolor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </SvgIcon>
  );
};
export default PhoneOutlinedIcon;
