import React from 'react';
import { DialogActions, Dialog, Button, DialogTitle } from '@mui/material';
import { isFunction } from 'lodash';
import GiftIcon from '../Icon/GiftIcon';

const ConfirmDialog = ({ open, onClose, onConfirm, title, onBack, confirmText, backText }) => {
  const handleBack = () => {
    onClose();
    isFunction(onBack) && onBack();
  };
  const handleConfirm = () => {
    onClose();
    isFunction(onConfirm) && onConfirm();
  };
  return (
    <Dialog maxWidth={'xs'} open={open} onClose={onClose}>
      <DialogTitle align="center">
        <GiftIcon sx={{ fontSize: '6rem' }} />
        <br />
        {title}
      </DialogTitle>
      <DialogActions>
        <Button variant="text" sx={{ flex: 1 }} onClick={handleBack}>
          {backText}
        </Button>
        <Button variant="contained" onClick={handleConfirm} sx={{ flex: 1 }}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
