import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Unstable_Grid2 as Grid, Container, Card, CardHeader, CardActionArea, Button, ListItem, ListItemText, Typography, ListItemSecondaryAction } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PointIcon from '../Icon/PointIcon';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { apiGetRewards } from '../../../services/RewardService';
import { apiGetUserActiveRewards } from '../../../services/UsersService';
import { apiGetTotalLoyaltyPointsByUserId } from '../../../services/LoyaltyPointsService';
import RewardCard from '../Card/RewardCard';
import GiftIcon from '../Icon/GiftIcon';
import CheckedHeartIcon from '../Icon/CheckedHeartIcon';
import { useSelector } from 'react-redux';

const Loyalty = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth?.auth);
  const [rewards, setRewards] = useState([]);
  const [redeemedRewards, setRedeemedRewards] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);

  const getRewards = async () => {
    const resRewards = await apiGetRewards({ range: [0, 4] });
    if (resRewards && !resRewards.error) {
      setRewards(resRewards.data);
    }
  };

  const getRedeemedRewards = async () => {
    const resRedeemedRewards = await apiGetUserActiveRewards(auth?.user?.id, { range: [0, 4], filter: { owner_id: auth?.user?.id, is_redeemed: true } });
    if (resRedeemedRewards && !resRedeemedRewards.error) {
      setRedeemedRewards(resRedeemedRewards.data);
    }
  };

  const getTotalPoints = async () => {
    const resTotalPoints = await apiGetTotalLoyaltyPointsByUserId(auth?.user?.id);
    if (resTotalPoints && !resTotalPoints.error) {
      setTotalPoints(resTotalPoints.data.total_points);
    }
  };

  useEffect(() => {
    getTotalPoints();
    getRewards();
    getRedeemedRewards();
  }, []);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Card variant="outlined">
            <CardHeader
              avatar={<PointIcon />}
              title={
                <>
                  {'Your Points: '}
                  <Typography variant="h5" component="span" color="primary" fontWeight="bold">
                    {totalPoints}
                  </Typography>
                </>
              }
              action={
                <Button variant="outlined" component={Link} to="/get-points">
                  {'Get Points'}
                </Button>
              }
            />
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card variant="outlined">
            <CardActionArea component={Link} to="/my-rewards">
              <CardHeader alignItems="center" title={<GiftIcon />} subheaderTypographyProps={{ color: 'primary' }} subheader={'Rewards'} action={<ArrowForwardIosIcon />} />
            </CardActionArea>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card variant="outlined">
            <CardActionArea component={Link} to="/wishlists">
              <CardHeader alignItems="center" title={<CheckedHeartIcon />} subheaderTypographyProps={{ color: 'primary' }} subheader={'Wishlist'} action={<ArrowForwardIosIcon />} />
            </CardActionArea>
          </Card>
        </Grid>
        <Grid xs={12}>
          <ListItem>
            <ListItemText>{'Recommended Rewards'}</ListItemText>
            <ListItemSecondaryAction>
              <Button component={Link} to="/rewards">
                View all
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </Grid>
        <Grid xs={12}>
          <Swiper
            className="mySwiper-3"
            speed={1500}
            slidesPerView={4}
            spaceBetween={30}
            loop={false}
            autoplay={{
              delay: 2000,
            }}
            modules={[Autoplay]}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {rewards.map((data, index) => (
              <SwiperSlide key={index}>
                <RewardCard data={data} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Grid xs={12}>
          <ListItem>
            <ListItemText>{'Redeemed Rewards'}</ListItemText>
            <ListItemSecondaryAction>
              <Button component={Link} to="/rewards">
                View all
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </Grid>
        <Grid xs={12}>
          <Swiper
            className="mySwiper-3"
            speed={1500}
            slidesPerView={4}
            spaceBetween={30}
            loop={false}
            autoplay={{
              delay: 2000,
            }}
            modules={[Autoplay]}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {redeemedRewards.map((data, index) => (
              <SwiperSlide key={index}>
                <RewardCard data={data?.reward} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Loyalty;
