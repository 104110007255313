import React from 'react';
import { Card, CardContent, CardMedia, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Grid, Chip, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import starGroup from './../../../images/star-group.png';
import PointIcon from '../Icon/PointIcon';
import UserAvatar from '../Avatar/UserAvatar';
const CouponCard = ({ data, ...props }) => {
  const navigate = useNavigate();
  return (
    <Card
      variant="contained"
      sx={{
        color: '#FFF',
        width: '100%',
        border: 'none',
        background: 'linear-gradient(98.65deg, #0E91D7 2.56%, #0766C3 100%)',
        borderRadius: 1,
        height: 1 / 1,
      }}
    >
      <CardActionArea onClick={() => navigate(`/rewards/${data?.id}`, { state: data })}>
        <Grid container>
          <Grid xs={7} spacing={1}>
            <CardContent>
              <ListItem sx={{ height: 50 }} disablePadding disableGutters>
                <ListItemAvatar>
                  <UserAvatar data={data?.restaurant} />
                </ListItemAvatar>
                <ListItemText primaryTypographyProps={{ variant: 'h5', color: 'inherit' }}>{data?.restaurant?.name}</ListItemText>
              </ListItem>
              <Typography>{data.title}</Typography>
              <Chip sx={{ bgcolor: '#FFF', color: 'primary.main' }} size="small" icon={<PointIcon width={20} height={20} />} label={`+${data.price}`} />
            </CardContent>
          </Grid>
          <Grid xs={5} container alignItems="center" justifyContent="center">
            <CardMedia component="img" src={starGroup} alt="stars" sx={{ width: 100 }} />
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};
export default CouponCard;
