import React, { useState, useEffect } from 'react';
import { DialogActions, Dialog, Button, DialogTitle, DialogContent, FormLabel, FormControl, OutlinedInput, Rating } from '@mui/material';
import { useFormik } from 'formik';
import RestaurantListItem from '../List/RestaurantListItem';
import StarIcon from '../Icon/StarIcon';
import StarOutlinedIcon from '../Icon/StarOutlinedIcon';

const AddReviewDialog = ({ open, restaurant, onClose, onSubmit }) => {
  const formik = useFormik({ initialValues: {}, onSubmit });

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <RestaurantListItem data={restaurant} />
      </DialogTitle>
      <DialogContent>
        <FormControl margin="normal" fullWidth>
          <FormLabel shrink>{'Your overall rating of this resturant'}</FormLabel>
          <Rating
            // Props={{ sx: { mr: 3 } }}
            icon={<StarIcon rating={formik.values.rating} />}
            emptyIcon={<StarOutlinedIcon />}
            name="rating"
            value={formik.values.rating}
            onChange={(event, _value) => formik.setFieldValue('rating', _value)}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <FormLabel shrink>{'What did you like or dislike?'}</FormLabel>
          <OutlinedInput value={formik.values.comment} onChange={formik.handleChange('comment')} placeholder="Whrite a review...." multiline minRows={5} maxRows={10} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={formik.handleSubmit}>
          {'Submit Review'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddReviewDialog;
