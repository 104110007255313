import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const InfoOutlinedIcon = ({ ...props }) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M17.98 10.79V14.79C17.98 15.05 17.97 15.3 17.94 15.54C17.71 18.24 16.12 19.58 13.19 19.58H12.79C12.54 19.58 12.3 19.7 12.15 19.9L10.95 21.5C10.42 22.21 9.56 22.21 9.03 21.5L7.82999 19.9C7.69999 19.73 7.41 19.58 7.19 19.58H6.79001C3.60001 19.58 2 18.79 2 14.79V10.79C2 7.86001 3.35001 6.27001 6.04001 6.04001C6.28001 6.01001 6.53001 6 6.79001 6H13.19C16.38 6 17.98 7.60001 17.98 10.79Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.98 6.79001V10.79C21.98 13.73 20.63 15.31 17.94 15.54C17.97 15.3 17.98 15.05 17.98 14.79V10.79C17.98 7.60001 16.38 6 13.19 6H6.79004C6.53004 6 6.28004 6.01001 6.04004 6.04001C6.27004 3.35001 7.86004 2 10.79 2H17.19C20.38 2 21.98 3.60001 21.98 6.79001Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6352 15.2L10.8732 8.34H9.1372L9.3612 15.2H10.6352ZM9.9912 18.098C10.5372 18.098 10.9852 17.664 10.9852 17.132C10.9852 16.586 10.5372 16.152 9.9912 16.152C9.4592 16.152 9.0112 16.586 9.0112 17.132C9.0112 17.664 9.4592 18.098 9.9912 18.098Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
export default InfoOutlinedIcon;
