import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from './jsx';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import FullScreenLoading from './jsx/components/FullScreenLoading';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import { initNetworkAction } from './store/actions/NetworkActions';
/// Style
import './css/style.css';``

const SendCode = lazy(() => import('./jsx/pages/Register'));
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const CreateAccount = lazy(() => import('./jsx/pages/CreateAccount'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const ForgotPasswordReset = lazy(() => import('./jsx/pages/ForgotPasswordSet'));
const FillInfo = lazy(() => import('./jsx/pages/FillInfo'));
const ContactUs = lazy(() => import('./jsx/components/Dashboard/ContactUs'));
const Landing = lazy(() => import('./jsx/pages/Landing'));
const FirstLogin = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./jsx/pages/FirstLogin')), 500);
  });
});
const SecondLogin = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./jsx/pages/SecondLogin')), 10);
  });
});
const Invite = lazy(() => import('./jsx/pages/Invite'));

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    //  checkAutoLogin(dispatch, navigate);
    dispatch(initNetworkAction());
  }, []);
  const loading = props.network && (props.network.loading.length > 0);
  
  const renderRouteblog = () => (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/first-step" element={<FirstLogin />} />
      <Route path="/login" element={<SecondLogin />} />
      <Route path="/send-code" element={<SendCode />} />
      <Route path="/fill-info" element={<FillInfo />} />
      <Route path="/register" element={<CreateAccount />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/page-forgot-password" element={<ForgotPassword />} />
      <Route path="/page-forgot-password-set" element={<ForgotPasswordReset />} />
      <Route path="/invite/:token" element={<Invite />} />
      <Route path="/*" element={<Landing />} />
    </Routes>
  );
  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      {props.isAuthenticated ? (
        <Index />
      ) : (
        <div className="vh-100">
          {renderRouteblog()}
        </div>
      )}
    </Suspense>
  );
  // if (props.isAuthenticated) {
  //   return (
  //     <>
  //       <Suspense
  //         fallback={
  //           <div id="preloader">
  //             <div className="sk-three-bounce">
  //               <div className="sk-child sk-bounce1"></div>
  //               <div className="sk-child sk-bounce2"></div>
  //               <div className="sk-child sk-bounce3"></div>
  //             </div>
  //           </div>
  //         }
  //       >
  //         <Index />
  //         <FullScreenLoading loading={props.network && (props.network.loading.length > 0)} />
  //       </Suspense>
  //     </>
  //   );
  // } else {
  //   return (
  //     <div className="vh-100">
  //       <Suspense
  //         fallback={
  //           <div id="preloader">
  //             <div className="sk-three-bounce">
  //               <div className="sk-child sk-bounce1"></div>
  //               <div className="sk-child sk-bounce2"></div>
  //               <div className="sk-child sk-bounce3"></div>
  //             </div>
  //           </div>
  //         }
  //       >
  //         {renderRouteblog()}
  //         <FullScreenLoading loading={props.network && (props.network.loading.length > 0)} />
  //       </Suspense>
  //     </div>
  //   );
  // }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    network: state.network,
    auth: state.auth,
  };
};

//export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App));
