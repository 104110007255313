import React from 'react';
import { Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';
import { getFullName, getInitialName } from '../../../utils/dataUtils';
import classnames from 'classnames';
import styles from './avatar-jss';

const UserAvatar = ({ data, classes, size, className, ...props }) => {
  const AVATAR_COLORS = ['blue', 'lightRed', 'lightYellow', 'lightGreen', 'lightBlue', 'yellowBlack'];
  const color = AVATAR_COLORS[data?.id % 5];
  return (
    <Avatar src={data?.logo || data?.image} classes={{ root: classes.avatarRoot }} className={classnames(className, size && classes[`${size}Size`], color && classes[`${color}Color`])} {...props}>
      {getInitialName(getFullName(data))}
    </Avatar>
  );
};
export default withStyles(styles)(UserAvatar);
