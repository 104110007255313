import React, { useState, useEffect } from 'react';
import { NotificationsOutlined } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoutPage from './Logout';
import { getInitialName, getFullName } from '../../../utils/dataUtils';
import { ListItem, Stack, ListItemIcon, Grid, Tabs, Tab, Container, Avatar, Box, ListItemText, IconButton, AppBar, Toolbar, Chip, ListItemAvatar, Menu, OutlinedInput, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { GlobalStyles } from '@mui/system';
import { useSelector } from 'react-redux';
import PointIcon from '../../components/Icon/PointIcon';
import SearchIcon from '../../components/Icon/SearchIcon';
import UserAvatar from '../../components/Avatar/UserAvatar';
import HomeOutlinedIcon from '../../components/Icon/HomeOutlinedIcon';
import TwoPersonOutlinedIcon from '../../components/Icon/TwoPersonOutlinedIcon';
import MedalOutlinedIcon from '../../components/Icon/MedalOutlinedIcon';
import LocationCheckedIcon from '../../components/Icon/LocationCheckedIcon';
import MoreSquareIcon from '../../components/Icon/MoreSquareIcon';
import { apiGetTotalLoyaltyPointsByUserId } from '../../../services/LoyaltyPointsService';

const Header = ({ }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.auth?.user);
  const isMobile = useMediaQuery('(max-width:788px)');
  const [searchKey, setSearchKey] = useState('');
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [mobileAnchorElMenu, setMobileAnchorElMenu] = useState(null);
  const [totalPoints, setTotalPoints] = useState(0);

  const getTotalPoints = async () => {
    const resTotalPoints = await apiGetTotalLoyaltyPointsByUserId(user?.id);
    if (resTotalPoints && !resTotalPoints.error) {
      setTotalPoints(resTotalPoints.data.total_points);
    }
  };

  useEffect(() => {
    location.pathname !== '/restaurants' && setSearchKey('');
  }, [location.pathname]);

  useEffect(() => {
    getTotalPoints();
  }, []);

  const onUserMenuItem = () => {
    setAnchorElMenu(null)
  };

  const renderMobileMenus = () => {
    return (
      <>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon sx={{ fontSize: '2rem' }} onClick={() => setMobileAnchorElMenu(true)} />
        </IconButton>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={mobileAnchorElMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(mobileAnchorElMenu)}
          onClose={() => setMobileAnchorElMenu(null)}
        >
          <ListItem button component={Link} to="/dashboard">
            <ListItemText>{'Dashboard'}</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/restaurants">
            <ListItemText>{'Restaurants'}</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/loyalty">
            <ListItemText>{'Loyalty'}</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/groups">
            <ListItemText>{'Groups'}</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/invite-friends">
            <ListItemText>{'Invite a Friend!'}</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="contact-us">
            <ListItemText>{'Contact Us'}</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="profile">
            <ListItemText>{'Profile'}</ListItemText>
          </ListItem>
          <LogoutPage />
        </Menu>
      </>
    );
  };

  const renderWebmenus = () => {
    return (
      <Tabs variant="scrollable" scrollButtons="auto" value={location.pathname}>
        <Tab sx={{ color: '#FFFFFF', '&:hover': { color: '#AAABB3' }, fontSize: '1.15rem' }} label="Dashboard" component={Link} to="/dashboard" />
        <Tab sx={{ color: '#FFFFFF', '&:hover': { color: '#AAABB3' }, fontSize: '1.15rem' }} label="Restaurants" component={Link} to="/restaurants" />
        {/* <Tab sx={{ color: '#FFFFFF', '&:hover': { color: '#AAABB3' }, fontSize: '1.15rem' }} label="Loyalty" component={Link} to="/loyalty" /> */}
        <Tab sx={{ color: '#FFFFFF', '&:hover': { color: '#AAABB3' }, fontSize: '1.15rem' }} label="Group" component={Link} to="/groups" />
        <Tab sx={{ color: '#FFFFFF', '&:hover': { color: '#AAABB3' }, fontSize: '1.15rem' }} label="Invite a Friend!" component={Link} to="/invite-friends" />
      </Tabs>
    );
  };

  const renderSearchBar = () => {
    return (
      <>
        <GlobalStyles
          styles={{
            '.customOutlinedInput input::selection': {
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
            },
          }}
        />
        <OutlinedInput
          fullWidth
          color="secondary"
          sx={{ color: '#FFF', border: '1px solid #E6E7EB', fontSize: '1rem' }}
          className="customOutlinedInput"
          value={searchKey}
          placeholder="Find a place to eat"
          onChange={(e) => setSearchKey(e.target.value)}
          onKeyDown={(e) =>
            e.key === 'Enter' &&
            !!searchKey &&
            navigate('./restaurants', {
              state: { searchKey },
            })
          }
          startAdornment={<SearchIcon sx={{ mr: 2 }} />}
        />
      </>      
    );
  };

  const renderUserMenus = () => {
    return (
      <>
        <Stack>
          <ListItem disableGutters disablePadding sx={{ width: 1 / 1, minWidth: 150 }} button onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <ListItemAvatar>
              <UserAvatar data={user} />
            </ListItemAvatar>
            <ListItemText>{getFullName(user)}</ListItemText>
            <ListItemIcon sx={{ ml: 2, mr: 0, minWidth: 'auto' }}>
              <i className="fas fa-chevron-down"></i>
            </ListItemIcon>
          </ListItem>
        </Stack>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElMenu)}
          onClose={() => setAnchorElMenu(null)}
        >
          <ListItem button component={Link} to="profile" onClick={onUserMenuItem}>
            <ListItemIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-primary"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </ListItemIcon>
            <ListItemText>{'Profile'}</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="edit-profile" onClick={onUserMenuItem}>
            <ListItemIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-primary"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
              </svg>
            </ListItemIcon>
            <ListItemText>{'Edit Profile'}</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/my-notifications" onClick={onUserMenuItem}>
            <ListItemIcon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                    fill="var(--primary)"
                  />
                  <circle fill="var(--primary)" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                </g>
              </svg>
            </ListItemIcon>
            <ListItemText>{'Notification'}</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="contact-us" onClick={onUserMenuItem}>
            <ListItemIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-primary"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
            </ListItemIcon>
            <ListItemText>{'Contact Us'}</ListItemText>
          </ListItem>
          <LogoutPage />
        </Menu>
      </>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Container sx={{ maxWidth: '100%!important', paddingLeft: '0px!important', paddingRight: '0px!important' }}>
          <Toolbar>
            {isMobile && renderMobileMenus()}
            <Link to="/dashboard" className="brand-logo" style={{ marginRight: 'auto' }}>
              <img src={require('../../../images/blue-round-logo.png')} alt="logo" />
            </Link>
            <Grid container>
              <Grid item xs={3} md={4} lg={3} container alignItems='center'>
                {(!isMobile || window.location.pathname == '/restaurants') && renderSearchBar()}
              </Grid>
              <Grid item xs={3} md={4} lg={5} container alignItems='center'>
                {!isMobile && renderWebmenus()}
              </Grid>
              <Grid item xs={6} md={4} lg={4} container justifyContent='flex-end'>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Chip
                    component={Link}
                    to="/loyalty"
                    clickable
                    onClick={() => navigate('/loyalty')}
                    icon={<PointIcon width={35} height={35} fontSize='large' />}
                    label={totalPoints}
                    sx={{
                      border: '1px solid #E6E7EB',
                      p: 1,
                      height: 'auto',
                      borderRadius: 3,
                      fontSize: 20,
                      boxShadow: '0px 6.19355px 12.3871px -3.09677px rgba(30, 60, 120, 0.1), 0px 3.09677px 6.19355px -3.09677px rgba(30, 60, 120, 0.06)',
                      color: '#FFF',
                      '&:hover': { color: '#AAABB3' }
                    }}
                  />
                  <IconButton
                    component={Link}
                    to="/my-notifications"
                    sx={{
                      border: '1px solid #E6E7EB',
                      p: 1,
                      boxShadow: '0px 6.19355px 12.3871px -3.09677px rgba(30, 60, 120, 0.1), 0px 3.09677px 6.19355px -3.09677px rgba(30, 60, 120, 0.06)',
                      color: '#FFF',
                      '&:hover': { color: '#AAABB3' }
                    }}
                  >
                    <NotificationsOutlined sx={{ width: 32, height: 27 }} />
                  </IconButton>
                  {!isMobile && renderUserMenus()}
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Header;
