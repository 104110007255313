import React, { useState } from 'react';
import { Button, Avatar, TextField, Container, IconButton, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PointIcon from '../Icon/PointIcon';
import ExchangeIcon from '../Icon/ExchangeIcon';

const BuyPoints = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth?.auth);

  const initialValues = {
    points: '',
    price: '',
  };
  const validationSchema = Yup.object({
    points: Yup.number().required('Required'),
    price: Yup.number().required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  return (
    <Container>
      <TextField
        label="You Get"
        InputLabelProps={{ shrink: true }}
        onChange={formik.handleChange('points')}
        id="points"
        name="points"
        fullWidth
        margin="points"
        error={formik.touched.points && formik.errors.points}
        helperText={formik.touched.points && formik.errors.points}
        placeholder={0}
        InputProps={{ endAdornment: <PointIcon /> }}
      />
      <Stack alignItems="center">
        <IconButton>
          <ExchangeIcon />
        </IconButton>
      </Stack>
      <TextField
        label="You Send"
        InputLabelProps={{ shrink: true }}
        onChange={formik.handleChange('price')}
        id="price"
        name="price"
        fullWidth
        margin="normal"
        error={formik.touched.price && formik.errors.price}
        helperText={(formik.touched.points && formik.errors.points) || 'X% fees (minimal X USD) are included in the price.'}
        placeholder={0}
        InputProps={{ endAdornment: <Avatar>{'$'}</Avatar> }}
      />
      <Button disabled={!formik.dirty} variant="contained" fullWidth onClick={formik.handleSubmit}>
        {'Continue'}
      </Button>
    </Container>
  );
};
export default BuyPoints;
