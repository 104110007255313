import axios from 'axios';
import swal from 'sweetalert';
import { restApiSettings } from '../config/api';
import { loginConfirmedAction, Logout } from '../store/actions/AuthActions';
import { query, jsonQuery } from './common';

export function signUp(data) {
  //axios call
  return axios({
    url: `${restApiSettings.baseURL}/register`,
    method: 'POST',
    data,
    headers: { 'Content-Type': 'application/json' },
  });
}

export function login(username, password) {
  const data = {
    username,
    password,
  };
  return axios({
    url: `${restApiSettings.baseURL}/token`,
    method: 'POST',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
}

export function formatError(status) {
  switch (status) {
    case 409:
      //return 'Email already exists';
      swal('Oops', 'Email or phone number already exists', 'error');
      break;
    case 'EMAIL_NOT_FOUND':
      //return 'Email not found';
      swal('Oops', 'Email not found', 'error', { button: 'Try Again!' });
      break;
    case 'INVALID_PASSWORD':
      //return 'Invalid Password';
      swal('Oops', 'Invalid Password', 'error', { button: 'Try Again!' });
      break;
    case 'USER_DISABLED':
      return 'User Disabled';

    default:
      return '';
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  // tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000,
  // );
  localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem('userDetails');
  let tokenDetails = '';
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}


export const apiLogin = async (data) => await jsonQuery(
  `/token`, 'POST', data, true, true, {'Content-Type': 'application/x-www-form-urlencoded'}, ''
);

export const apiFillInfo = async (data) => await jsonQuery(
  `/fillinfo`, 'POST', data, true, true, {}, ''
);

export const apiSignUp = async (data) => await jsonQuery(
  `/register`, 'POST', data, true, true, {}, ''
);

export const apiVerifyExist = async (data) => await jsonQuery(
  `/verify_exist`, 'POST', data, true, true, {}, ''
);


export const apiValidateCode = async (data) => await jsonQuery(
  `/validate_code`, 'POST', data, true, true, {}, ''
);



export const apiSendCode = async (data) => await jsonQuery(
  `/send_code`, 'POST', data, true, true, {}, ''
);

export const apiForgotPassword = async (data) => await jsonQuery(
  `/forgot_password`, 'POST', data, true, true, {}, ''
);

export const apiForgotPasswordReset = async (data) => await jsonQuery(
  `/reset_password/change`, 'POST', data, true, true, {}, ''
);



export const apiSingup = async (data) => await jsonQuery(
  `/register`, 'POST', data, true, true, {}, ''
);


export const apiVerifyCode = async (data) => await jsonQuery(
  `/verify_code`, 'POST', data, true, true, {}, ''
);
