import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5C396D',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F7F1E3',
    },
    white: {
      main: '#FFFFFF',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#d30606',
    },
    success: {
      main: '#79b075',
    },
    currency: {
      main: '#FBBC03',
    },
    text: {
      primary: '#3a1e47',
      secondary: '#2d2648',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F5f5f5',
      surface: '#E6e7eb',
    },
    grey: {
      700: '#AAABB3',
      900: '#E6e7eb',
    },
  },
  breakpoints: {
    xxl: 1800,
  },
  typography: {
    fontFamily: 'Alata',
    h5: {
      fontSize: '1.25rem',
      lineHeight: 1.45,
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: 1.45,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.45,
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.45,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.45,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.45,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.45,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 'regular',
      lineHeight: 1.45,
    },
  },
  shape: { borderRadius: 10 },
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: ({ theme }) => ({
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.surface,
        }),
        root: ({ theme }) => ({
          width: theme.spacing(6),
          height: theme.spacing(6),
        }),
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        textSizeLarge: {
          // fontSize: '1.125rem',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          ...theme.typography.button,
          // }),
          // colorDefault: ({ theme }) => ({
          backgroundColor: '#FFF',
          color: theme.palette.primary.main,
        }),
        outlined: {
          border: '1.5px solid #FFFFFF',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            backgroundColor: '#FFF',
            border: '1px solid #E6E7EB',
            borderRadius: theme.shape.borderRadius * 2,
          }),
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 30,
          marginRight: 5,
          color: 'currentcolor',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {},
      },
    },
    MuiChip: {
      styleOverrides: {
        iconColorDefault: {
          color: '#AAABB3',
        },
        deleteIcon: {
          color: 'currentcolor',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: '0.5em',
        },
      },
    },
  },
});

export default theme;
