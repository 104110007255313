import { combineReducers } from 'redux';
import PostsReducer from './reducers/PostsReducer';
import { connectRouter } from 'connected-react-router';
import { AuthReducer } from './reducers/AuthReducer';
import { NetworkReducer } from './reducers/NetworkReducer';
import todoReducers from './reducers/Reducers';
import { createBrowserHistory } from 'history';

export default (injectedReducers = {}) => {
  const rootReducer = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
    network: NetworkReducer,
    todoReducers,
    ...injectedReducers,
  });
  const mergeWithRouterState = connectRouter(createBrowserHistory());

  return mergeWithRouterState(rootReducer);
};
