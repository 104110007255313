import React from 'react';
import { Avatar, CardMedia, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Box, CardContent } from '@mui/material';
import { getInitialName } from '../../../utils/dataUtils';
import RatingListItem from '../List/RatingListItem';

const RestaurantCardContent = ({ data }) => {
  return (
    <CardContent>
      <CardMedia component="img" src={data?.image || require('../../../images/resturent-review/yogaimage.png')} alt="" style={{ width: '100%', height: 150 }} />
      <ListItem sx={{ transform: 'translateY( -50% )' }}>
        <ListItemAvatar>
          <Avatar
            sx={{
              width: 90,
              height: 90,
              border: '4px solid #F5F5F5',
              fontSize: '2rem',
            }}
            src={data?.image}
          >
            {getInitialName(data?.name)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText sx={{ transform: 'translateY(-50%)' }}>
          <ListItem sx={{ color: '#FFF' }}>
            <ListItemIcon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.38 12.0001L10.79 14.4201L15.62 9.58008" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M10.75 2.45031C11.44 1.86031 12.57 1.86031 13.27 2.45031L14.85 3.81031C15.15 4.07031 15.71 4.28031 16.11 4.28031H17.81C18.87 4.28031 19.74 5.15031 19.74 6.21031V7.91031C19.74 8.30031 19.95 8.87031 20.21 9.17031L21.57 10.7503C22.16 11.4403 22.16 12.5703 21.57 13.2703L20.21 14.8503C19.95 15.1503 19.74 15.7103 19.74 16.1103V17.8103C19.74 18.8703 18.87 19.7403 17.81 19.7403H16.11C15.72 19.7403 15.15 19.9503 14.85 20.2103L13.27 21.5703C12.58 22.1603 11.45 22.1603 10.75 21.5703L9.17 20.2103C8.87 19.9503 8.31 19.7403 7.91 19.7403H6.18C5.12 19.7403 4.25 18.8703 4.25 17.8103V16.1003C4.25 15.7103 4.04 15.1503 3.79 14.8503L2.44 13.2603C1.86 12.5703 1.86 11.4503 2.44 10.7603L3.79 9.17031C4.04 8.87031 4.25 8.31031 4.25 7.92031V6.20031C4.25 5.14031 5.12 4.27031 6.18 4.27031H7.91C8.3 4.27031 8.87 4.06031 9.17 3.80031L10.75 2.45031Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </ListItemIcon>
            <ListItemText> {'You have been here.'} </ListItemText>
            <Box>
              <RatingListItem data={data?.rating} />
            </Box>
          </ListItem>
        </ListItemText>
      </ListItem>
    </CardContent>
  );
};

export default RestaurantCardContent;
