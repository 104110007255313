import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const LogoutIcon = ({ ...props }) => {
  return (
    <SvgIcon width="24" height="26" viewBox="0 0 24 26" {...props}>
      <path
        d="M8.8999 7.56023C9.2099 3.96023 11.0599 2.49023 15.1099 2.49023H15.2399C19.7099 2.49023 21.4999 4.28023 21.4999 8.75023V15.2702C21.4999 19.7402 19.7099 21.5302 15.2399 21.5302H15.1099C11.0899 21.5302 9.2399 20.0802 8.9099 16.5402"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M15.0001 12H3.62012" stroke="currentColor" fill="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.85 8.65039L2.5 12.0004L5.85 15.3504" stroke="currentColor" fill="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </SvgIcon>
  );
};
export default LogoutIcon;
