import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Unstable_Grid2 as Grid, Container, Pagination } from '@mui/material';
import { get, split, last } from 'lodash';
import RewardCard from '../Card/RewardCard';
import { apiGetRewards } from '../../../services/RewardService';

const Rewards = () => {
  const location = useLocation();
  const [rewards, setRewards] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_count: 0,
    per_page: 12,
  });
  const searchKey = location?.state?.searchKey;

  const getRewards = async () => {
    const range = [(pagination.current_page - 1) * pagination.per_page, pagination.current_page * pagination.per_page - 1];

    const resRewards = await apiGetRewards({
      filter: { search_key: searchKey },
      range,
    });
    if (resRewards && !resRewards.error) {
      const total_count = Number(last(split(get(resRewards, 'headers.content-range'), '/')) || 0);
      setRewards(resRewards.data);
      setPagination({ ...pagination, total_count });
    }
  };

  useEffect(() => {
    getRewards();
  }, [searchKey, pagination.current_page]);

  return (
    <Container>
      <Grid container spacing={2}>
        {rewards.map((item) => (
          <Grid md={3} sm={4} xs={6}>
            <RewardCard data={item} key={item.id} />
          </Grid>
        ))}
      </Grid>
      <Grid component="nav" container justifyContent="center" sx={{ py: 2 }}>
        <Pagination count={Math.ceil(pagination.total_count / pagination.per_page)} page={pagination.current_page} onChange={(_, page) => setPagination({ ...pagination, current_page: page })} />
      </Grid>
    </Container>
  );
};
export default Rewards;
