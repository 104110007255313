import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Chip, Container, Stack, Typography, Unstable_Grid2 as Grid, Alert } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { last, split, get } from 'lodash';
import 'swiper/css';
import EventCard from '../Card/EventCard';
import RewardCard from '../Card/RewardCard';
import EventDialog from '../Dialog/EventDialog';
import PointIcon from '../Icon/PointIcon';
import CouponCard from '../Card/CouponCard';
import RestaurantCard from '../Card/RestaurantCard';
import { apiGetUserEvents, apiGetUserActiveRewards, apiGetUserRecommendedRestaurants } from '../../../services/UsersService';
import { apiGetRewards } from '../../../services/RewardService';
import { apiGetTotalLoyaltyPointsByUserId } from '../../../services/LoyaltyPointsService';

const Home = () => {
  const auth = useSelector((state) => state.auth?.auth);
  const [rewards, setRewards] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventCount, setEventCount] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState();
  const [actveRewards, setActiveRewards] = useState([]);
  const [recommendedRestaurants, setRecommendedRestaurants] = useState([]);
  const [totalLoyaltyPoints, setTotalLoyaltyPoints] = useState(0);

  const getRewards = async () => {
    const resRewards = await apiGetRewards();
    if (resRewards && !resRewards.error) {
      setRewards(resRewards.data);
    }
  };

  const getActiveRewards = async () => {
    const resRewards = await apiGetUserActiveRewards(auth?.user?.id);
    if (resRewards && !resRewards.error) {
      setActiveRewards(resRewards.data);
    }
  };

  const getRecommendedRestaurants = async () => {
    const resRestaurants = await apiGetUserRecommendedRestaurants(auth?.user?.id, {range: [0, 10]});
    if (resRestaurants && !resRestaurants.error) {
      setRecommendedRestaurants(resRestaurants.data);
    }
  };

  const getEvents = async () => {
    const resEvents = await apiGetUserEvents(auth?.user.id, { range: [0, 3], sort: 'updated_at' });
    if (resEvents && !resEvents.error) {
      setEventCount(Number(last(split(get(resEvents, 'headers.content-range'), '/')) || 0));
      setEvents(resEvents.data);
    }
  };

  const getTotalLoyaltyPoints = async () => {
    const res = await apiGetTotalLoyaltyPointsByUserId(auth?.user?.id);
    if (res && !res.error) {
      setTotalLoyaltyPoints(res.data.total_points);
    }
  }

  useEffect(() => {
    getEvents();
    getActiveRewards();
    getRewards();
    getRecommendedRestaurants();
    getTotalLoyaltyPoints();
  }, [auth?.user.id]);

  return (
    <Container sx={{marginBottom: 2}}>
      {!auth?.user?.zip_code && (
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small" component={Link} to="/edit-profile">
              {'Get Location'}
            </Button>
          }
        >
          {`In order to better help you find places to enjoy dinning we require some basic location information, at a minimum a zipcode. If you allow GPS we do not track any historical GPS locations. We do not sell data to third parties with your personal information. When searching, you can provide a new location to search in, that is not your home location.`}
        </Alert>
      )}

      <Grid container spacing={1}>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography color="text.secondary">{'Recommended'}</Typography>
            <Button component={Link} to="/rewards">
              {'View all'}
            </Button>
          </Stack>
          <Swiper
            className="mySwiper-3"
            speed={1500}
            slidesPerView={4}
            spaceBetween={30}
            loop={false}
            autoplay={{
              delay: 2000,
            }}
            modules={[Autoplay]}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {rewards.map((reward, index) => (
              <SwiperSlide key={index}>
                <CouponCard data={reward} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography color="text.secondary">{`My Events (${eventCount})`}</Typography>
            <Button component={Link} to="/events" size="large">
              {'View all'}
            </Button>
          </Stack>
          <Swiper
            className="mySwiper-3"
            speed={1500}
            slidesPerView={4}
            spaceBetween={30}
            loop={false}
            autoplay={{
              delay: 2000,
            }}
            modules={[Autoplay]}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {events.map((data, index) => (
              <SwiperSlide key={index}>
                <EventCard data={data} onClick={() => setSelectedEvent(data)} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography color="text.secondary">
              {'My Rewards'}
              <Chip sx={{ bgcolor: '#FFF', color: 'primary.main', ml: 0.5 }} size="small" icon={<PointIcon width={20} height={20} />} label={`${totalLoyaltyPoints}`} />
            </Typography>
            <Button component={Link} to="/my-rewards" size="large">
              {'View all'}
            </Button>
          </Stack>
          <Swiper
            className="mySwiper-3"
            speed={1500}
            slidesPerView={4}
            spaceBetween={30}
            loop={false}
            autoplay={{
              delay: 2000,
            }}
            modules={[Autoplay]}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {actveRewards.map((data, index) => (
              <SwiperSlide key={index}>
                <RewardCard data={data.reward} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography color="text.secondary">
      		  {'Recommended Restaurants'}
            </Typography>
            <Button component={Link} to="/my-rewards" size="large">
              {'View all'}
            </Button>
          </Stack>
          <Swiper
            className="mySwiper-3"
            speed={1500}
            slidesPerView={4}
            spaceBetween={30}
            loop={false}
            autoplay={{
              delay: 2000,
            }}
            modules={[Autoplay]}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
          {recommendedRestaurants.map((data, index) => (
              <SwiperSlide key={index}>
                <RestaurantCard data={data} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
      {selectedEvent?.id && <EventDialog data={selectedEvent} open={selectedEvent?.id} user={auth?.user} onClose={() => setSelectedEvent(null)} />}
    </Container>
  );
};
export default Home;
