import React, { useState, useEffect } from 'react';
import { DialogActions, Dialog, Button, DialogTitle, DialogContent, OutlinedInput, Stack, Typography, FormControl, Slider, TextField, FormLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useFormik } from 'formik';
import { withStyles } from '@mui/styles';
import * as yup from 'yup';
import { apiGetMenuCategories } from '../../../services/MenusService';

const RATINGS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];
const PRICE_SYMBOLS = ['$', '$$', '$$$', '$$$$'];

function valuetext(value) {
  return `${value}`;
}


const FilterDialog = ({ filter, open, onClose, onSubmit }) => {
  const [categories, setCategories] = useState([]);
  const formik = useFormik({ initialValues: {location: filter?.location}, onSubmit });

  const getCategories = async () => {
    const resCategories = await apiGetMenuCategories();
    if (resCategories && !resCategories.error) {
      setCategories(resCategories.data);
    }
  };

  useEffect(() => {
    if (filter) {
      formik.setValues({
        prices: filter.prices || [],
        rate_range: filter.rate_range || [0, 5],
        location: filter.location || '',
        categories: filter.categories || []
      });
    }
  }, [filter]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{'Filters'}</DialogTitle>
      <DialogContent>
      <FormControl margin="normal" fullWidth>
          <FormLabel shrink>{'Prices'}</FormLabel>
          <ToggleButtonGroup sx={{ flexWrap: 'wrap' }} value={formik.values.prices} onChange={(_, values) => formik.setFieldValue('prices', values.length > 0 ? values : null)}>
            {PRICE_SYMBOLS.map((price) => (
              <ToggleButton
                sx={{
                  m: 2,
                  borderRadius: '20px !important',
                  border: '1px solid #E6E7EB',
                  '&.Mui-selected': {
                    bgcolor: 'primary',
                  },
                }}
                value={price}
                color="primary"
                valueLabelDisplay="auto"
                marks
              >
                {price}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <FormLabel shrink>{'Rate range'}</FormLabel>
          <Slider
            aria-label="rate_range"
            name="rate_range"
            min={0}
            step={0.5}
            max={5}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => `${value}`}
            defaultValue={[0, 0]}
            marks={RATINGS}
            value={formik.values.rate_range}
            onChange={(_, value) => formik.setFieldValue('rate_range', value)}
          />
          {/* <Stack direction="row" justifyContent="space-between">
            <Typography color="primary">
              {'$'}
            </Typography>
            <Typography color="primary">{`$$$$`}</Typography>
          </Stack> */}
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <FormLabel shrink>{'Location'}</FormLabel>
          <OutlinedInput
            value={formik.values.location}
            name={'location'}
            onChange={formik.handleChange('location')}
            onBlur={formik.handleBlur('location')}
            endAdornment={<MyLocationIcon color="primary" />}
            placeholder="location...."
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <FormLabel shrink>{'Categories'}</FormLabel>
          <ToggleButtonGroup sx={{ flexWrap: 'wrap' }} value={formik.values.categories} onChange={(_, values) => formik.setFieldValue('categories', values.length > 0 ? values : null)}>
            {categories.map((category) => (
              <ToggleButton
                sx={{
                  m: 2,
                  borderRadius: '20px !important',
                  border: '1px solid #E6E7EB',
                  '&.Mui-selected': {
                    bgcolor: 'primary',
                  },
                }}
                value={category}
                color="primary"
              >
                {category}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={formik.handleSubmit}>
          {'Apply Filters'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
