import React from 'react';
import { Button, CardActions, TextField, Container, Card, CardContent, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { apiCreateContactUs } from '../../../services/ContactUsService';
import * as Yup from 'yup';

const ContactUs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth?.auth);

  const handleSend = async (data) => {
    const resContactUs = await apiCreateContactUs(data);
  };

  const initialValues = {
    email: '',
    mobile_phone: '',
    message: '',
  };
  const validationSchema = Yup.object({
    email: Yup.string().email('No email type').required('Required'),
    mobile_phone: Yup.number().required('Required'),
    message: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: handleSend,
    validationSchema,
  });

  return (
    <Container sx={{ height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ p: 1 }}>
        <Typography variant="h3" align="center" fontWeight="bold" color="primary.main">
          {'Contact Us'}
        </Typography>
        <CardContent>
          <TextField
            id="email"
            label="Email"
            InputLabelProps={{ shrink: true }}
            onChange={formik.handleChange('email')}
            name="email"
            fullWidth
            margin="normal"
            error={formik.touched.email && formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            id="mobile_phone"
            label="Mobile Phone"
            type="number"
            InputLabelProps={{ shrink: true }}
            onChange={formik.handleChange('mobile_phone')}
            name="mobile_phone"
            fullWidth
            margin="normal"
            error={formik.touched.mobile_phone && formik.errors.mobile_phone}
            helperText={formik.touched.mobile_phone && formik.errors.mobile_phone}
          />
          <TextField
            id="message"
            multiline
            number
            label="Message"
            InputLabelProps={{ shrink: true }}
            onChange={formik.handleChange('message')}
            name="message"
            margin="normal"
            fullWidth
            minRows={5}
            error={formik.touched.message && formik.errors.message}
            helperText={formik.touched.message && formik.errors.message}
          />
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={formik.handleSubmit} fullWidth>
            {'Contact Us'}
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};
export default ContactUs;
