import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
  circularProgress: {
    position: 'fixed',
    top: 'calc(50% - 45px)',
    left: 'calc(50% - 45px)',
  }
};

const Loading = () => <CircularProgress sx={styles.circularProgress} size={90} thickness={1} color="secondary" />;

export default Loading;
