import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, OutlinedInput, Stack, ToggleButton, Unstable_Grid2 as Grid, Badge, Pagination, Typography } from '@mui/material';
import { apiGetGroupRecommendedRestaurants } from '../../../services/GroupService';
import RestaurantCard from '../Card/RestaurantCard';
import { get, split, last, omitBy, keys, isEmpty } from 'lodash';
import TuneIcon from '@mui/icons-material/Tune';
import MagnifyExclamationSymboIcon from '../Icon/MagnifyExclamationSymboIcon';
import FilterDialog from './FilterDialog';

const RestaurantsDialog = ({ open, data, onClose }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [filter, setFilter] = useState({});
  const [filterDialogVisible, setFilterDialogVisible] = useState(false);
  const [pagination, setPagination] = useState({ currentPage: 1, totalCount: 0, perPage: 2 });

  const handleFilter = (values) => {
    setFilterDialogVisible(false);
    setFilter(omitBy({ ...filter, ...values }, (v) => !v));
  };

  const getRecommendedRestaurants = async () => {
    const range = [(pagination.currentPage - 1) * pagination.perPage, pagination.currentPage * pagination.perPage - 1];
    const resRestaurants = await apiGetGroupRecommendedRestaurants(data?.id, { filter, range });
    if (resRestaurants && !resRestaurants.error) {
      const totalCount = Number(last(split(get(resRestaurants, 'headers.content-range'), '/')) || 0);
      setRestaurants(resRestaurants.data);
      setPagination({ ...pagination, totalCount });
    }
  };

  useEffect(() => {
    getRecommendedRestaurants();
  }, [data?.id, filter, pagination.currentPage]);

  return (
    <Dialog maxWidth={'lg'} PaperProps={{ sx: { minHeight: '50vh', minWidth: '50vw' } }} open={open} onClose={onClose}>
      <DialogTitle>{`Browse Restaurants for ‘${data.name}’`}</DialogTitle>
      <DialogContent>
        <Stack flexDirection="row" rowSpacing={1}>
          <OutlinedInput
            value={filter.searchKey}
            name="search_key"
            onChange={(e) => e.target.name && setFilter(omitBy({ ...filter, [e.target.name]: e.target.value }, (v) => !v))}
            placeholder="Search food or restaurants"
            fullWidth
          />
          <ToggleButton onClick={() => setFilterDialogVisible(true)}>
            <Badge badgeContent={keys(filter)?.length} color="success">
              <TuneIcon />
            </Badge>
          </ToggleButton>
        </Stack>
        <Typography>{isEmpty(omitBy(filter, (value) => value !== 0 && !value)) ? 'Recommended for our group' : 'Search Result'}</Typography>
        {pagination.totalCount > 0 ? (
          <>
            <Grid container spacing={1}>
              {restaurants.map((item) => (
                <Grid xs={12} md={6} sm={4}>
                  <RestaurantCard data={item} key={item.id} />
                </Grid>
              ))}
            </Grid>
            <Grid component="nav" container justifyContent="center" sx={{ py: 2 }}>
              <Pagination count={Math.ceil(pagination.totalCount / pagination.perPage)} page={pagination.currentPage} onChange={(_, page) => setPagination({ ...pagination, currentPage: page })} />
            </Grid>
          </>
        ) : (
          <>
            <MagnifyExclamationSymboIcon sx={{ width: 85, height: 85 }} />
            <Typography>{'Sorry, No Result Found!'}</Typography>
          </>
        )}
      </DialogContent>
      <FilterDialog open={filterDialogVisible} onSubmit={handleFilter} onClose={() => setFilterDialogVisible(false)} />
    </Dialog>
  );
};

export default RestaurantsDialog;
