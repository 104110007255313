import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Chip } from '@mui/material';
import { getFullName, getInitialName } from '../../../utils/dataUtils';
import { deepOrange, yellow } from '@mui/material/colors';
import UserAvatar from '../Avatar/UserAvatar';

const UserListItem = ({ data, children, ...props }) => {
  return (
    <ListItem alignItems="center" {...props}>
      <ListItemAvatar sx={{ flexDirection: 'column', alignItems: 'center', display: 'flex' }}>
        <UserAvatar data={data} />
        {data?.level && (
          <Chip
            label={data?.level}
            sx={{
              bgcolor: '#FFF',
              borderRadius: 0.5,
              border: '1.5px solid #FFFFFF',
              boxShadow: ' 0px 1px 3px rgba(30, 60, 120, 0.1), 0px 1px 2px rgba(30, 60, 120, 0.06)',
              zIndex: 100,
              mt: -1,
            }}
            size="small"
          />
        )}
      </ListItemAvatar>
      <ListItemText primaryTypographyProps={{ color: 'text.secondary' }} secondaryTypographyProps={{ color: 'grey.700' }} primary={getFullName(data)} secondary={data.phone_number} />
      {children}
    </ListItem>
  );
};
export default UserListItem;
