import React, { useState, useEffect } from 'react';
import {
  Button, Avatar, TextField, Typography, Container, 
  IconButton, Stack, ListItemText, ListItem, ListItemIcon
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PointIcon from '../Icon/PointIcon';
import ExchangeIcon from '../Icon/ExchangeIcon';
import { useSelector, useDispatch } from 'react-redux';
import { updateAction } from '../../../store/actions/AuthActions';
import { apiGetUserById, apiUpdateUserById } from '../../../services/UsersService';
import { apiGetLoyaltyPointsByUserId, apiGetTotalLoyaltyPointsByUserId } from '../../../services/LoyaltyPointsService';
import { formatDate } from '../../../utils/dateTimeUtils';

const DEFAULT_POINTS = [
  { description: 'I have invited a friend', points: 50, link: '/invite-friends', variableName: null },
  { description: 'I have allowed GPS location', points: 200, link: '/edit-profile', variableName: 'geolocation' },
  { description: 'I have set zipcode', points: 100, link: '/edit-profile', variableName: 'zip_code'},
]

const PURCHASE_POINTS = [
  { description: 'Purchase $1.99', points: 10, price: 1.99 },
  { description: 'Purchase $12', points: 70, price: 12 },
  { description: 'Purchase $35', points: 150, price: 35 }
]

const GetPoints = () => {
  const auth = useSelector((state) => state.auth?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loyaltyPoints, setLoyaltyPoints] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);

  const getLoyaltyPoints = async () => {
    const resLoyaltyPoints = await apiGetLoyaltyPointsByUserId(auth?.user?.id, {sort: ['id', "DESC"]});
    if (resLoyaltyPoints && !resLoyaltyPoints.error) {
      setLoyaltyPoints(resLoyaltyPoints.data);
    }
  };

  const getTotalPoints = async () => {
    const resTotalPoints = await apiGetTotalLoyaltyPointsByUserId(auth?.user?.id);
    if (resTotalPoints && !resTotalPoints.error) {
      setTotalPoints(resTotalPoints.data.total_points);
    }
  };

  const handelGetPoints = (link) => {
    navigate(link);
  };

  const handlePurChasePoints = (purchasePoint) => {
    navigate('/purchase-points', { state: { purchasePoint: purchasePoint } });
  };

  useEffect(() => {
    getLoyaltyPoints();
    getTotalPoints();
  }, []);

  return (
    <Container>
      <ListItem sx={{ border: '1.15152px solid #E6E7EB', bgcolor: '#FFF', borderRadius: 0.7, mb: 1 }}>
        <ListItemText
          primary={(
            <Typography variant="h6" color="primary">
              {'Total Points'}
            </Typography>
          )}
        />
        <Typography variant="h6" color="error.main">
          {totalPoints}
        </Typography>
        <PointIcon />
      </ListItem>
      
      <Typography
        color="text.secondary" 
        sx={{paddingLeft: '4px', paddingTop: '8px', paddingBottom: '8px'}}
      >
        {`Get More Points`}
      </Typography>
      {PURCHASE_POINTS.map(data => 
        <ListItem sx={{ border: '1.15152px solid #E6E7EB', bgcolor: '#FFF', borderRadius: 0.7, mb: 1 }}>
          <ListItemText
            primary={data.description}
            secondary={
              <ListItem disablePadding>
                <PointIcon />
                <Typography variant="subtitle2" color="error.main">
                  {data.points}
                </Typography>
              </ListItem>
            }
          />
          <Button onClick={() => handlePurChasePoints(data)}>
            {`Buy Points`}
          </Button>
        </ListItem>
      )}
      {DEFAULT_POINTS.map(data => 
        <ListItem sx={{ border: '1.15152px solid #E6E7EB', bgcolor: '#FFF', borderRadius: 0.7, mb: 1 }}>
          <ListItemText
            primary={data.description}
            secondary={
              <ListItem disablePadding>
                <PointIcon />
                <Typography variant="subtitle2" color="error.main">
                  {data.points}
                </Typography>
              </ListItem>
            }
          />
          {(data.variableName && auth.user[data.variableName]) ? (
            <CheckCircleIcon color="primary" />
          ) : (
            <Button onClick={() => handelGetPoints(data.link)}>
              {'Get Points'}
            </Button>
          )}
        </ListItem>
      )}

      <Typography
        color="text.secondary" 
        sx={{paddingLeft: '4px', paddingTop: '8px', paddingBottom: '8px'}}
      >
        {`Histories`}
      </Typography>
      {loyaltyPoints.map(data =>
        <ListItem sx={{border: '1.15152px solid #E6E7EB', bgcolor: '#FFF', borderRadius: 0.7, mb: 1}}>
          <ListItemText primary={`${formatDate(data.created_at, 'MM/DD/YY hh:mm A')}`} sx={{textAlign: 'left', width: '20%'}} />
          <ListItemText primary={(<Typography align='left'>{data.description}</Typography>)} sx={{textAlign: 'left', width: '60%'}} />
          <ListItemText
            sx={{textAlign: 'left', width: '20%'}} 
            primary={(
              <div
                style={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  textDecoration: 'none',
                  width: '100%',
                  boxSizing: 'border-box',
                  textAlign: 'left',
                }}
              >
                <Typography variant="subtitle2" color="error.main">
                  {data.points}
                </Typography>
                <PointIcon />                
              </div>
            )}
          />
        </ListItem>
      )}
    </Container>
  );
};
export default GetPoints;
