import React, { useState } from 'react';
import { Tab, Container, IconButton } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import TuneOutlinedIcon from '../Icon/TuneOutlinedIcon';
import QuizOutlinedIcon from '../Icon/QuizOutlinedIcon';
import InfoOutlinedIcon from '../Icon/InfoOutlinedIcon';
import SettingIcon from '../Icon/SettingIcon';
import MessageOulinedIocn from '../Icon/MessageOulinedIocn';
import LogoutIcon from '../Icon/LogoutIcon';
import { useSelector } from 'react-redux';
import UserListItem from '../List/UserListItem';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { get } from 'lodash';
import FAQPage from './FAQ';
import MyReviewList from './MyReviewList';
import Preference from './Preference';
import LocationIcon from '../Icon/LocationIcon';
import Notifications from './Notifications';

const PROFILE_MENUS = [
  // { value: 'notification', label: 'Notification', icon: <TuneOutlinedIcon />, container: <Notifications /> },
  { value: 'become', label: 'Become a Restaurant', icon: <SettingIcon /> },
  { value: 'gps', label: 'GPS Setting', icon: <LocationIcon /> },
];

const Settings = () => {
  const routeParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth?.auth);
  const [tabIndex, setTabIndex] = useState(get(PROFILE_MENUS, '0.value'));

  return (
    <Container>
      <TabContext value={tabIndex}>
        <TabList sx={{ borderRadius: 1 }} onChange={(_, value) => setTabIndex(value)}>
          {PROFILE_MENUS.map((menu) => (
            <Tab {...menu} iconPosition="start" />
          ))}
        </TabList>
        {PROFILE_MENUS.map((menu) => (
          <TabPanel value={menu.value}>{menu.container}</TabPanel>
        ))}
      </TabContext>
    </Container>
  );
};
export default Settings;
