import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import StarIcon from '../Icon/StarIcon';

const RatingListItem = ({ data, color, sx, ...props }) => {
  const rating = data || 0;
  return (
    <ListItem component='span' disablePadding sx={[{ color: color || '#FFF', width: 'fit-content' }, sx]} {...props}>
      <ListItemIcon>
        <StarIcon rating={rating} />
      </ListItemIcon>
      <ListItemText primary={rating} sx={{ my: 0 }} />
    </ListItem>
  );
};
export default RatingListItem;
