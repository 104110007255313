import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, FormControlLabel, Button, FormLabel, FormControl, Checkbox, FormGroup, Unstable_Grid2 as Grid, Switch } from '@mui/material';
import { styled } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { apiGetUserById, apiUpdateUserById } from '../../../services/UsersService';
import { updateAction } from '../../../store/actions/AuthActions';

const NOTIFICATION_SETTING_OPTIONS = [
  { label: 'SMS', value: 'phone_notification' },
  { label: 'Push notifications', value: 'push_notification' },
  { label: 'Email notifications', value: 'email.notification' },
];
const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        // backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      // color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      // opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    // backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    // transition: theme.transitions.create(['background-color'], {
    //   duration: 500,
    // }),
  },
}));

const Notifications = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth?.auth);
  const handleUpdateUser = async (e) => {
    if (e.target.name) {
      const resUser = await apiUpdateUserById(auth?.user?.id, { ...auth?.user, [e.target.name]: e.target.checked });
      if (resUser && !resUser.error) {
        dispatch(updateAction(resUser.data));
      }
    }
  };
  const getUserDetail = async () => {
    const resUser = await apiGetUserById(auth?.user?.id);
    if (resUser && !resUser.error) {
      dispatch(updateAction(resUser.data));
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid xs={12} sm={6} md={4}>
          <FormControl>
            <FormLabel>{'Notification'}</FormLabel>
            <FormGroup>
              {NOTIFICATION_SETTING_OPTIONS.map((option) => (
                <FormControlLabel
                  sx={{ justifyContent: 'space-between' }}
                  labelPlacement="start"
                  onClick={handleUpdateUser}
                  control={<Switch name={option.value} checked={auth?.user[option.value]} />}
                  label={option.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Notifications;
