import React, { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QuestionIcon from '../Icon/QuestionIcon';
import WorkingPersonIcon from '../Icon/WorkingPersonIcon';

const StatusAvatar = ({ status, sx, ...props }) => {
  let bgcolor = '';
  let color = '#FFF';
  let Icon = QuestionIcon;
  switch (status) {
    case 'yes':
      bgcolor = 'success.main';
      Icon = CheckIcon;
      break;
    case 'on_my_way':
      bgcolor = 'success.main';
      Icon = WorkingPersonIcon;
      break;
    case 'no':
      bgcolor = 'error.main';
      Icon = CloseIcon;
      break;
    default:
      color = 'primary.main';
      break;
  }
  return (
    <Avatar sx={[{ width: 18, height: 18, fontSize: 12, border: '1px solid #FFF', bgcolor, color }, sx]} {...props}>
      <Icon fontSize="inherit" />
    </Avatar>
  );
};

export default StatusAvatar;
