import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, FormControlLabel, Button, FormLabel, FormControl, Checkbox, FormGroup, Unstable_Grid2 as Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { includes, get } from 'lodash';
import { useFormik } from 'formik';
import OutlinedCircleIcon from '../Icon/OutlinedCircleIcon';
import RoundedCheckIcon from '../Icon/RoundedCheckIcon';
import { apiGetUserPreference, apiCreateUserPreference } from '../../../services/UsersService';
import { apiUpdateUserPreference } from '../../../services/UserPreferenceService';

const PREFERENCE_OPTIONS = [
  {
    label: 'Meat?',
    value: 'meat',
    subOptions: [
      { label: 'Vegan/Vegeterial', value: 'Vegan' },
      { label: 'Seafood Only', value: 'Seafood' },
      { label: 'All Meats', value: 'All' },
    ],
  },
  {
    label: 'Alcohol?',
    value: 'alcohol',
    subOptions: [
      { label: 'No Alcohol', value: 'No' },
      { label: 'Beer & Wine', value: 'Beer & Wine' },
      { label: 'Any', value: 'Any' },
    ],
  },
  {
    label: 'Allergies?',
    value: 'allergy',
    subOptions: [
      { label: 'Gluten', value: 'Gluten' },
      { label: 'Nuts', value: 'Nuts' },
      { label: 'Shellfish', value: 'Shellfish' },
      { label: 'Soy', value: 'Soy' },
      { label: 'Dairy', value: 'Dairy' },
    ],
  },
];

const Preference = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth?.auth);
  const handleClickOption = (e) => {
    if (!e.target.name) {
      return;
    }
    const oldValues = get(formik.values, e.target.name) || [];
    const newValues = includes(oldValues, e.target.value) ? oldValues.filter((_value) => _value !== e.target.value) : [...oldValues, e.target.value];
    formik.setFieldValue(e.target.name, newValues);
  };

  const getUserPreference = async () => {
    const resUserPreference = await apiGetUserPreference(auth?.user?.id);
    if (resUserPreference && !resUserPreference.error) {
      resUserPreference.data && formik.setValues(resUserPreference.data);
    }
  };

  const createOrUpdateUserPreference = async (data) => {
    let resUserPreference;
    if (!data.id) {
      resUserPreference = await apiCreateUserPreference(auth?.user?.id, { user_id: auth?.user?.id, ...data });
    } else {
      resUserPreference = await apiUpdateUserPreference(data.id, data);
    }
    if (resUserPreference && !resUserPreference.error) {
      formik.setValues(resUserPreference.data);
    }
  };

  useEffect(() => {
    getUserPreference();
  }, []);

  const formik = useFormik({
    initialValues: {},
    onSubmit: createOrUpdateUserPreference,
  });
  return (
    <Container>
      <Grid container spacing={1}>
        {PREFERENCE_OPTIONS.map((option) => (
          <Grid xs={12} sm={6} md={4}>
            <FormControl>
              <FormLabel>{option.label}</FormLabel>
              <FormGroup>
                {option.subOptions.map((subOption) => (
                  <FormControlLabel
                    onClick={handleClickOption}
                    control={
                      <Checkbox
                        name={option.value}
                        value={subOption.value}
                        checked={includes(formik.values[option.value], subOption.value)}
                        icon={<OutlinedCircleIcon />}
                        checkedIcon={<RoundedCheckIcon sx={{ color: 'success.main' }} />}
                      />
                    }
                    label={subOption.label}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Button variant="contained" onClick={formik.handleSubmit}>
        {'Save'}
      </Button>
    </Container>
  );
};
export default Preference;
