import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Unstable_Grid2 as Grid, TextField, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSelector } from 'react-redux';
import { useFormik, Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { apiSendInvitation } from '../../../services/UsersService';
import PhoneNumberInput from '../TextBox/PhoneNumberInput';
import PhoneNumberTextField from '../TextBox/PhoneNumberTextField';

const InviteFriends = () => {
  const rootUrl = window.location.origin;
  const auth = useSelector((state) => state.auth?.auth);
  const [link, setLink] = useState(auth ? `${rootUrl}/invite/${auth.user.invitation_token}` : '');
  const [isCopied, setIsCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const handleCopyLink = async () => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(link);
        setIsCopied(true);
      } else {
        const el = document.createElement('textarea');
        el.value = link;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        await new Promise((resolve) => setTimeout(resolve, 100));
        document.body.removeChild(el);
        setIsCopied(true);
      }
    } catch (err) {
      console.error('Failed to copy link: ', err);
    }
  };

  const handleSend = async (data) => {
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const res = await apiSendInvitation(
        auth?.user?.id,
        { email: data.email, phone_number: data.phoneNumber, link },
      )
      setSuccessMessage('Successfully Sent Invitation link.');
    } catch (error) {
      console.error('Error Sending an invitation link:', error);
      setErrorMessage(error?.response?.data?.detail || 'Error occurred while sending an invitation link.');
    }
   
  };

  const initialValues = {
    email: '',
    phoneNumber: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address'),
    phoneNumber: Yup.string().matches(
      /^\+1\s\d{3}\s\d{3}\s\d{4}$/,
      'Please enter a valid US phone number'
    ),
  }).test('at-least-one-field', 'Please enter at least one field', function (value) {
    return value.email || value.phoneNumber;
  });

  const formik = useFormik({
    initialValues,
    onSubmit: handleSend,
    validationSchema,
  });
 
  return (
    <Container>
      <Card >
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSend}
          >
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <TextField
                  label="Copy Link"
                  variant="outlined"
                  value={link}
                  size="small"
                  InputProps={{ readOnly: true }}
                  fullWidth
                  sx={{ mb: 2, mt: 2 }}
                />
              </Grid>
              <Grid item md={4} xs={12} textAlign="right">
                <Button
                  variant="contained"
                  onClick={handleCopyLink}
                  startIcon={<FileCopyIcon />}
                  sx={{ mb: 2, mt: 2 }}
                >
                  {isCopied ? 'Copied!' : 'Copy'}
                </Button>
              </Grid>

              <Stack alignItems="center" spacing={2} sx={{width: '100%'}}>
                {errorMessage && (
                  <Typography align="center" sx={{ mb: 2, color: 'red' }}>{errorMessage}</Typography>
                )}
                {successMessage && (
                  <Typography align="center" sx={{ mb: 2, color: 'green' }}>{successMessage}</Typography>
                )}
              </Stack>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  placeholder="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                  error={formik.touched.email && formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Field
                  name="phoneNumber"
                  render={({ field, form }) => ( */}
                    <PhoneNumberTextField
                      label="Phone Number"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      placeholder="+1 000 000 0000"
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange('phoneNumber')}
                      // onBlur={formik.handleBlur('phoneNumber')}
                      // error={formik.touched.phoneNumber && formik.errors.phoneNumber}
                      // helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                      sx={{ mb: 2 }} 
                    />
                  {/* )}
                /> */}
                {/* <TextField
                  label="Phone number"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  placeholder="+1 000 000 0000"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange('phoneNumber')}
                  onBlur={formik.handleBlur('phoneNumber')}
                  error={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  sx={{ mb: 2 }} 

                />*/}
              </Grid>
              <Grid item xs={12} textAlign="right">
                <Button
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
};
export default InviteFriends;
