import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const MagnifyExclamationSymboIcon = ({ ...props }) => {
  return (
    <SvgIcon width="85" height="85" viewBox="0 0 85 85" {...props}>
      <path
        d="M40.7291 74.3747C59.3112 74.3747 74.375 59.3109 74.375 40.7288C74.375 22.1468 59.3112 7.08301 40.7291 7.08301C22.1471 7.08301 7.08331 22.1468 7.08331 40.7288C7.08331 59.3109 22.1471 74.3747 40.7291 74.3747Z"
        stroke="currentcolor"
        fill="transparent"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path opacity="0.4" d="M77.9166 77.9163L70.8333 70.833" stroke="currentcolor" fill="transparent" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
      <path
        opacity="0.4"
        d="M39.988 25.676H44.596L43.912 43.604H40.672L39.988 25.676ZM42.22 53.396C41.5 53.396 40.888 53.156 40.384 52.676C39.88 52.172 39.628 51.548 39.628 50.804C39.628 50.084 39.868 49.472 40.348 48.968C40.852 48.464 41.476 48.212 42.22 48.212C42.964 48.212 43.576 48.464 44.056 48.968C44.56 49.472 44.812 50.084 44.812 50.804C44.812 51.548 44.56 52.172 44.056 52.676C43.576 53.156 42.964 53.396 42.22 53.396Z"
        fill="#AAABB3"
      />
    </SvgIcon>
  );
};
export default MagnifyExclamationSymboIcon;
