import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const LocationIcon = ({ ...props }) => {
  return (
    <SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13 14.55C14.8667 14.55 16.38 13.0368 16.38 11.17C16.38 9.30332 14.8667 7.79004 13 7.79004C11.1333 7.79004 9.62 9.30332 9.62 11.17C9.62 13.0368 11.1333 14.55 13 14.55Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
      />
      <path
        d="M3.92155 9.19783C6.05572 -0.183838 19.9549 -0.173005 22.0782 9.20866C23.3241 14.712 19.9007 19.3703 16.8999 22.252C14.7224 24.3537 11.2774 24.3537 9.08905 22.252C6.09906 19.3703 2.67572 14.7012 3.92155 9.19783Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
      />
    </SvgIcon>
  );
};
export default LocationIcon;
