import React from 'react';
import { AvatarGroup } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import UserAvatar from './UserAvatar';
import styles from './avatar-jss';
import { isArray } from 'lodash';

const UserAvatarGroup = ({ classes, data, children, max = 5, size }) => {
  return (
    <AvatarGroup componentsProps={{ additionalAvatar: { className: classnames(size && classes[`${size}Size`]) } }} max={max}>
      {isArray(data) && data.map((member) => <UserAvatar data={member} size={size} />)}
      {children}
    </AvatarGroup>
  );
};
export default withStyles(styles)(UserAvatarGroup);
