import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Container,
  FormControl,
  FormLabel,
  Checkbox,
  Box,
  FormControlLabel,
  Avatar,
  Typography,
  IconButton,
  Step,
  Stepper,
  StepButton,
  Card,
  CardContent,
  CardActions,
  Alert,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserListItem from '../List/UserListItem';
import { apiCreateGroup } from '../../../services/GroupService';
import { apiCreateUserGroup, apiGetUsers } from '../../../services/UsersService';
import { find, filter, map } from 'lodash';
import { getFullName, getInitialName } from '../../../utils/dataUtils';
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateGroup = () => {
  const navigate = useNavigate;
  const auth = useSelector((state) => state.auth?.auth);
  const [activeStep, setActiveStep] = useState(0);
  const [users, setUsers] = useState([]);
  const initialValues = {
    name: '',
    member_ids: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
  });

  const handleSelectUser = (e, _user) => {
    const members = e.target.checked ? [...selectedUsers, _user] : selectedUsers.filter((selectedUser) => selectedUser.id !== _user.id);
    formik.setFieldValue(
      'member_ids',
      members.map((member) => member.id)
    );
  };

  const handleCreateGroup = async (data) => {
    const resGroup = await apiCreateUserGroup(auth?.user?.id, { ...data, author_id: auth?.user?.id });
    if (resGroup && !resGroup.error) {
      setActiveStep(activeStep + 1);
      // setGroup(resGroup.data);
    }
  };

  const getUsers = async () => {
    const resUsers = await apiGetUsers({ filter: { id_not: auth?.user.id } });
    if (resUsers && !resUsers.error) {
      setUsers(resUsers.data);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: handleCreateGroup,
    validationSchema,
  });

  const selectedUsers = filter(users, (user) => formik.values.member_ids.includes(user.id));

  const steps = ['Create Group', 'Add Contact', 'Finish'];

  const renderView = () => {
    switch (activeStep) {
      case 0:
        return (
          <Card>
            <CardContent>
              <TextField
                name="name"
                label="Group Name"
                InputLabelProps={{ shrink: true }}
                placeholder="Name the group"
                value={formik.values.name}
                onChange={formik.handleChange('name')}
                onBlur={formik.handleBlur('name')}
                error={formik.touched.name && formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
                margin="dense"
              />
            </CardContent>
            <CardActions>
              <Button variant="contained" disabled={formik.errors.name} onClick={() => setActiveStep(activeStep + 1)}>
                {'Next'}
              </Button>
            </CardActions>
          </Card>
        );
      case 1:
        return (
          <Card>
            <CardContent>
              <FormControl>
                <FormLabel>{'Who would you like to add?'}</FormLabel>
                {users.map((user) => (
                  <FormControlLabel
                    control={<Checkbox name={user.id} color="success" checked={formik.values.member_ids.includes(user.id)} onChange={(e) => handleSelectUser(e, user)} />}
                    label={<UserListItem data={user} />}
                  />
                ))}
              </FormControl>
              <Box sx={{ display: 'flex' }}>
                {selectedUsers.map((_user) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      mr: 2,
                    }}
                  >
                    <Avatar>{getInitialName(getFullName(_user))}</Avatar>
                    <IconButton
                      size="small"
                      onClick={() => setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== _user.id))}
                      sx={{
                        mt: -2,
                        bgcolor: '#fff',
                        p: 0.2,
                        alignSelf: 'flex-end',
                      }}
                    >
                      <CancelIcon color="error" />
                    </IconButton>
                    <Typography>{getFullName(_user)}</Typography>
                  </Box>
                ))}
              </Box>
            </CardContent>
            <CardActions>
              <Button variant="contained" onClick={formik.handleSubmit}>
                {'Add Selected Contacts'}
              </Button>
            </CardActions>
          </Card>
        );
      case 2:
        return <Alert severity="success">Your contacts added to your group.!</Alert>;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Stepper nonLinear activeStep={activeStep} sx={{ mb: 2 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton color="inherit">{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      {renderView()}
    </Container>
  );
};
export default CreateGroup;
