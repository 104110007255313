import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Unstable_Grid2 as Grid, Container, Pagination } from '@mui/material';
import { filter } from 'lodash';
import RewardCard from '../Card/RewardCard';
import { apiDeleteWishlist } from '../../../services/WishlistService';
import { useSelector } from 'react-redux';
import { apiGetUserWishlists } from '../../../services/UsersService';

const Wishlists = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth?.auth);
  const [wishlists, setWishlists] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_count: 0,
    per_page: 12,
  });

  const handleDeleteWishlist = async (wishlist) => {
    const resWishlist = await apiDeleteWishlist(wishlist.id);
    if (resWishlist && !resWishlist.error) {
      setWishlists(filter(wishlists, (_wishlist) => _wishlist.id !== resWishlist.data.id));
    }
  };

  const getWishlists = async () => {
    const range = [(pagination.current_page - 1) * pagination.per_page, pagination.current_page * pagination.per_page - 1];
    const resWishlists = await apiGetUserWishlists(auth?.user?.id, { range });
    if (resWishlists && !resWishlists.error) {
      setWishlists(resWishlists.data);
    }
  };

  useEffect(() => {
    getWishlists();
  }, [pagination.current_page]);

  return (
    <Container>
      <Grid container spacing={2}>
        {wishlists.map((item) => (
          <Grid md={3} sm={4} xs={6}>
            <RewardCard data={item.reward} onDelete={() => handleDeleteWishlist(item)} key={item.id} />
          </Grid>
        ))}
      </Grid>
      <Grid component="nav" container justifyContent="center" sx={{ py: 2 }}>
        <Pagination count={Math.ceil(pagination.total_count / pagination.per_page)} page={pagination.current_page} onChange={(_, page) => setPagination({ ...pagination, current_page: page })} />
      </Grid>
    </Container>
  );
};
export default Wishlists;
