import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Unstable_Grid2 as Grid, Container, List, ToggleButtonGroup, ToggleButton, Badge, Typography, Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { apiGetRestaurants } from '../../../services/RestaurantsService';
import RestaurantCard from '../Card/RestaurantCard';
import RestaurantListItem from '../List/RestaurantListItem';
import { get, split, last, omitBy, isNil, keys } from 'lodash';
import TuneIcon from '@mui/icons-material/Tune';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FilterDialog from '../Dialog/FilterDialog';
import MagnifyExclamationSymboIcon from '../Icon/MagnifyExclamationSymboIcon';
import { useSelector } from 'react-redux';
import FullScreenLoading from '../FullScreenLoading';

const SearchRestaurants = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [restaurants, setRestaurants] = useState([]);
  const [viewValue, setViewValue] = useState('GRID');
  const auth = useSelector((state) => state.auth?.auth);
  const [filter, setFilter] = useState({});
  const [filterDialogVisible, setFilterDialogVisible] = useState(false);
  const [pagination, setPagination] = useState({ currentPage: 1, totalCount: 0, perPage: 12 });
  const [isFetchedLocation, setIsFetchedLocation] = useState(false);
  const [loading, setLoading] = useState(true);
  const searchKey = location?.state?.searchKey;

  const handleFilter = (values) => {
    setFilterDialogVisible(false);
    setFilter(omitBy(values, (v) => !v));
  };

  const getRestaurants = async () => {
    setLoading(true);
    const range = [(pagination.currentPage - 1) * pagination.perPage, pagination.currentPage * pagination.perPage - 1];

    const resRestaurants = await apiGetRestaurants({
      filter: { search_key: searchKey, ...filter },
      range,
    });
    if (resRestaurants && !resRestaurants.error) {
      const totalCount = Number(last(split(get(resRestaurants, 'headers.content-range'), '/')) || 0);
      setRestaurants(resRestaurants.data);
      setPagination({ ...pagination, totalCount });
    }
    setLoading(false);
  };

  useEffect(() => {
    if(isFetchedLocation){
      getRestaurants();
    }
  }, [searchKey, pagination.currentPage, filter]);

  useEffect(() => {
     getUserLocation();
  }, []);
  
  const getUserLocation = async () => {
    console.log('==== getting location...');
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      setFilter({ location: auth.user.zip_code });
      setIsFetchedLocation(true);
      return;
    }
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
      if (permissionStatus.state === 'granted') {
        navigator.geolocation.getCurrentPosition(async (position) => {
          console.log('==== position: ', position);
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const apiKey = process.env.REACT_APP_OPENCAGE_API_KEY || '1b5c04ee33f9430783734fc1c389fd1d';
          const response = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`);
          const data = await response.json();
          console.log('==== location data: ', data);

          const { postcode } = data.results[0].components;
          setFilter({ location: postcode });
          setIsFetchedLocation(true);
        });
      } else {
        setFilter({ location: auth.user.zip_code });
        setIsFetchedLocation(true);
        console.error('Permission to access location was denied');
      }
    } catch (error) {
      setFilter({ location: auth.user.zip_code });
      setIsFetchedLocation(true);
      console.error(`Error: ${error.message}`);
    }
  };


  // useEffect(() => {
  //   getRestaurants();
  // }, [pagination.currentPage]);
  

  return (
    <Container>
      <FullScreenLoading loading={loading}/>
      <Grid container sx={{ mb: 2 }}>
        <ToggleButtonGroup exclusive value={viewValue} onChange={(_, value) => setViewValue(value)}>
          <ToggleButton value="LIST">
            <FormatListBulletedIcon />
          </ToggleButton>
          <ToggleButton value="GRID">
            <ViewModuleIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButton sx={{ ml: 1 }} onClick={() => setFilterDialogVisible(true)}>
          <Badge badgeContent={keys(filter)?.length} color="success">
            <TuneIcon />
          </Badge>
        </ToggleButton>
      </Grid>
      {pagination.totalCount === 0 ? (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
          <MagnifyExclamationSymboIcon sx={{ fontSize: '6rem' }} />
          <Typography color="text.secondary" align="center">
            {'Sorry, No Result Found!'}
          </Typography>
        </Box>
      ) : (
        <>
          {viewValue === 'LIST' ? (
            <List>
              {restaurants.map((item) => (
                <RestaurantListItem
                  data={item}
                  key={item.id}
                  button
                  onClick={() => navigate(`/restaurant/${item.id}`, { state: item })}
                  showRating
                  RatingItemProps={{ color: 'yellow' }}
                ></RestaurantListItem>
              ))}
            </List>
          ) : viewValue === 'GRID' ? (
            <Grid container spacing={2}>
              {restaurants.map((item) => (
                <Grid md={3} sm={4} xs={6}>
                  <RestaurantCard data={item} key={item.id} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <></>
          )}
          <Grid component="nav" container justifyContent="center" sx={{ py: 2 }}>
            <Pagination count={Math.ceil(pagination.totalCount / pagination.perPage)} page={pagination.currentPage} onChange={(_, page) => setPagination({ ...pagination, currentPage: page })} />
          </Grid>
        </>
      )}

      <FilterDialog filter={filter} open={filterDialogVisible} onSubmit={handleFilter} onClose={() => setFilterDialogVisible(false)} />
    </Container>
  );
};
export default SearchRestaurants;
