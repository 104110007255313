import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const GiftIcon = ({ ...props }) => {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24" sx={{ fill: 'transparent' }} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.97 10H3.96997V18C3.96997 21 4.96997 22 7.96997 22H15.97C18.97 22 19.97 21 19.97 18V10Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.5 7V8C21.5 9.1 20.97 10 19.5 10H4.5C2.97 10 2.5 9.1 2.5 8V7C2.5 5.9 2.97 5 4.5 5H19.5C20.97 5 21.5 5.9 21.5 7Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.64 5.00043H6.12003C5.78003 4.63043 5.79003 4.06043 6.15003 3.70043L7.57003 2.28043C7.94003 1.91043 8.55003 1.91043 8.92003 2.28043L11.64 5.00043Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.8701 5.00043H12.3501L15.0701 2.28043C15.4401 1.91043 16.0501 1.91043 16.4201 2.28043L17.8401 3.70043C18.2001 4.06043 18.2101 4.63043 17.8701 5.00043Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.93994 10V15.14C8.93994 15.94 9.81994 16.41 10.4899 15.98L11.4299 15.36C11.7699 15.14 12.1999 15.14 12.5299 15.36L13.4199 15.96C14.0799 16.4 14.9699 15.93 14.9699 15.13V10H8.93994Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
export default GiftIcon;
