import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const QuestionIcon = ({ ...props }) => {
  return (
    <SvgIcon width="8" height="10" viewBox="0 0 8 10" {...props}>
      <path
        d="M4.532 7L4.546 5.922C6.142 5.544 7.08 4.466 7.08 3.024C7.08 1.204 5.778 0 3.846 0C2.026 0 0.696 1.19 0.5 3.15H2.096C2.18 2.1 2.88 1.47 3.832 1.47C4.84 1.47 5.47 2.142 5.47 3.038C5.47 3.92 4.896 4.634 3.412 4.858L3.118 4.9V7H4.532ZM3.776 9.898C4.322 9.898 4.77 9.464 4.77 8.932C4.77 8.386 4.322 7.952 3.776 7.952C3.23 7.952 2.796 8.386 2.796 8.932C2.796 9.464 3.23 9.898 3.776 9.898Z"
        fill="currentcolor"
      />
    </SvgIcon>
  );
};
export default QuestionIcon;
