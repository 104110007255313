import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const QuizOutlinedIcon = ({ ...props }) => {
  return (
    <SvgIcon width="24" height="26" viewBox="0 0 24 26" {...props}>
      <path
        d="M9 23H15C20 23 22 21 22 16V10C22 5 20 3 15 3H9C4 3 2 5 2 10V16C2 21 4 23 9 23Z"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.328 16.634V14.96C14.38 14.438 15.532 13.052 15.532 11.216C15.532 8.93 13.876 7.4 11.5 7.4C9.268 7.4 7.63 8.894 7.396 11.342H8.926C9.052 9.812 10.078 8.858 11.5 8.858C12.958 8.858 13.948 9.848 13.948 11.216C13.948 12.476 13.192 13.502 11.14 13.844L10.816 13.898V16.634H12.328ZM11.482 20.108C12.076 20.108 12.562 19.64 12.562 19.064C12.562 18.488 12.076 18.02 11.482 18.02C10.924 18.02 10.438 18.488 10.438 19.064C10.438 19.64 10.924 20.108 11.482 20.108Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
export default QuizOutlinedIcon;
