import React, { useEffect, useState } from 'react';
import { Typography, Tab, Tabs, ListItemIcon, ListItemText, ListItem, IconButton, Box, ListItemAvatar, Stack, Avatar, Container, Button, CardMedia, Unstable_Grid2 as Grid } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { apiGetRestaurantById, apiCheckVistedRestaurant, } from '../../../../services/RestaurantsService';
import { apiUpdateVisitedRestaurant, apiCreateVisitedRestaurant } from '../../../../services/VisitedRestaurantsService';
import { getInitialName } from '../../../../utils/dataUtils';
import RatingListItem from '../../List/RatingListItem';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Menus from './Menus';
import Reviews from './Reviews';
import Information from './Information';
import { useSelector } from 'react-redux';

function Restaurant() {
  const routeParams = useParams();
  const restaurantId = Number(routeParams?.id);
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.auth?.user);
  const [restaurant, setRestaurnat] = useState(location.state || {});
  const [tabIndex, setTabIndex] = useState('1');
  const [visitedRestaurant, setVisitedRestaurant] = useState();
  const [hasReviews, setHasReviews] = useState(false);

  const handleFavorite = async () => {
    if (!visitedRestaurant?.id) {
      handleVisitRestaurant({ is_favorite: !visitedRestaurant?.is_favorite, restaurant_id: restaurantId, visitor_id: user?.id })
    } else {
      const resVisitedRestaurant = await apiUpdateVisitedRestaurant(visitedRestaurant?.id, { ...visitedRestaurant, is_favorite: !visitedRestaurant?.is_favorite });
      if (resVisitedRestaurant && !resVisitedRestaurant.error) {
        setVisitedRestaurant(resVisitedRestaurant.data);
      }
    }
  }
  const handleLike = async () => {
    if (!visitedRestaurant?.id) {
      handleVisitRestaurant({ is_liked: true, is_disliked: false, restaurant_id: restaurantId, visitor_id: user?.id })
    } else {
      const resVisitedRestaurant = await apiUpdateVisitedRestaurant(visitedRestaurant?.id, { ...visitedRestaurant, is_liked: !visitedRestaurant?.is_liked, is_disliked: false });
      if (resVisitedRestaurant && !resVisitedRestaurant.error) {
        setVisitedRestaurant(resVisitedRestaurant.data);
      }
    }
  }
  const handleDislike = async () => {

    if (!visitedRestaurant?.id) {
      handleVisitRestaurant({ is_liked: false, is_disliked: true, restaurant_id: restaurantId, visitor_id: user?.id })
    } else {
      const resVisitedRestaurant = await apiUpdateVisitedRestaurant(visitedRestaurant?.id, { ...visitedRestaurant, is_liked: false, is_disliked: !visitedRestaurant?.is_disliked });
      if (resVisitedRestaurant && !resVisitedRestaurant.error) {
        setVisitedRestaurant(resVisitedRestaurant.data);
      }
    }
  }

  const getRestaurant = async () => {
    const resRestaurant = await apiGetRestaurantById(routeParams.id);
    if (resRestaurant && !resRestaurant.error) {
      setRestaurnat(resRestaurant.data);
    }
  };

  const checkVisitedRestaurant = async () => {
    const resVisitedRestaurant = await apiCheckVistedRestaurant(routeParams.id, user?.id);
    if (resVisitedRestaurant && !resVisitedRestaurant.error) {
      if (resVisitedRestaurant.data?.visited_restaurant) {
        setVisitedRestaurant(resVisitedRestaurant.data);
      }
      if (resVisitedRestaurant.data?.has_review) {
        setHasReviews(true);
      }
    }
  };

  const handleVisitRestaurant = async (data = {}) => {
    const resVisitedRestaurant = await apiCreateVisitedRestaurant(data);
    if (resVisitedRestaurant && !resVisitedRestaurant.error) {
      setVisitedRestaurant(resVisitedRestaurant.data);
    }
  }

  useEffect(() => {
    getRestaurant();
    checkVisitedRestaurant();
  }, [restaurantId]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid xs={12} md={5} >
          <Typography variant="h4" component="h2">
            {restaurant.name}
          </Typography>
          <CardMedia component="img" src={restaurant.image || require('../../../../images/resturent-review/yogaimage.png')} alt="" style={{ width: '100%', height: '35vh' }} />
          <ListItem sx={{ transform: 'translateY( -50% )' }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: 120,
                  height: 120,
                  border: '4px solid #F5F5F5',
                  fontSize: '2rem',
                }}
                src={restaurant.logo}
              >
                {getInitialName(restaurant.name)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText sx={{ transform: 'translateY(-50%)' }}>
              <ListItem sx={{ color: '#FFF', padding: visitedRestaurant ? "0px" : "8px 16px" }}>
                <ListItemText >
                  {visitedRestaurant ? (
                    <ListItem>
                      <ListItemIcon>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.38 12.0001L10.79 14.4201L15.62 9.58008" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.75 2.45031C11.44 1.86031 12.57 1.86031 13.27 2.45031L14.85 3.81031C15.15 4.07031 15.71 4.28031 16.11 4.28031H17.81C18.87 4.28031 19.74 5.15031 19.74 6.21031V7.91031C19.74 8.30031 19.95 8.87031 20.21 9.17031L21.57 10.7503C22.16 11.4403 22.16 12.5703 21.57 13.2703L20.21 14.8503C19.95 15.1503 19.74 15.7103 19.74 16.1103V17.8103C19.74 18.8703 18.87 19.7403 17.81 19.7403H16.11C15.72 19.7403 15.15 19.9503 14.85 20.2103L13.27 21.5703C12.58 22.1603 11.45 22.1603 10.75 21.5703L9.17 20.2103C8.87 19.9503 8.31 19.7403 7.91 19.7403H6.18C5.12 19.7403 4.25 18.8703 4.25 17.8103V16.1003C4.25 15.7103 4.04 15.1503 3.79 14.8503L2.44 13.2603C1.86 12.5703 1.86 11.4503 2.44 10.7603L3.79 9.17031C4.04 8.87031 4.25 8.31031 4.25 7.92031V6.20031C4.25 5.14031 5.12 4.27031 6.18 4.27031H7.91C8.3 4.27031 8.87 4.06031 9.17 3.80031L10.75 2.45031Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </ListItemIcon>
                      <ListItemText> {'You have been here.'} </ListItemText>
                    </ListItem>
                  ) : (
                    <Button startIcon={<CheckIcon />} variant='contained' onClick={() => handleVisitRestaurant({ restaurant_id: restaurantId, visitor_id: user?.id })}>
                      {'Visited'}
                    </Button>
                  )}
                </ListItemText>
                <IconButton onClick={handleLike}>
                  <ThumbUpIcon sx={{ color: visitedRestaurant?.is_liked ? 'success.main' : '#FFF' }} />
                </IconButton>
                <IconButton onClick={handleDislike}>
                  <ThumbDownIcon sx={{ color: visitedRestaurant?.is_disliked ? 'error.main' : '#FFF' }} />
                </IconButton>
                <IconButton onClick={handleFavorite}>
                  {visitedRestaurant?.is_favorite ?
                    <FavoriteIcon sx={{ color: '#FFF' }} /> :
                    <FavoriteBorderIcon sx={{ color: '#FFF' }} />}
                </IconButton>
                <Box>
                  <RatingListItem data={restaurant?.rating} />
                </Box>
              </ListItem>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid xs={12} md={7}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabIndex}>
              <TabList
                TabIndicatorProps={{
                  sx: {
                    height: '100%',
                    borderRadius: 1,
                    zIndex: 0,
                  },
                }}
                sx={{
                  p: 2,
                  bgcolor: '#E6E7EB',
                  borderRadius: 1,
                }}
                onChange={(_, value) => setTabIndex(value)}
              >
                <Tab
                  label="Information"
                  value="1"
                  sx={{
                    color: '#AAABB3',
                    fontSize: 20,
                    zIndex: 10,
                    '&.Mui-selected': {
                      color: '#FFF',
                    },
                  }}
                />
                <Tab
                  label="Menu Items"
                  sx={{
                    color: '#AAABB3',
                    zIndex: 10,
                    fontSize: 20,
                    '&.Mui-selected': {
                      color: '#FFF',
                    },
                  }}
                  value="2"
                />
                <Tab
                  label="Reviews"
                  value="3"
                  sx={{
                    color: '#AAABB3',
                    zIndex: 10,
                    fontSize: 20,
                    '&.Mui-selected': {
                      color: '#FFF',
                    },
                  }}
                />
              </TabList>
              <TabPanel value="1">
                <Information data={restaurant} />
              </TabPanel>
              <TabPanel value="2">
                <Menus data={restaurant?.categories} />
              </TabPanel>
              <TabPanel value="3">
                <Reviews data={restaurant?.reviews} restaurant={restaurant} onUpdate={getRestaurant} />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
      <Stack alignItems="center">
        <Button variant="contained" size="large" onClick={() => navigate('/events/create', { state: restaurant })}>
          {'Create Event'}
        </Button>
      </Stack>
    </Container>
  );
}
export default Restaurant;
