import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const ExchangeIcon = () => {
  return (
    <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.8058 10.5596L26.4924 15.2463" stroke="#AAABB3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.8058 26.4387V10.5596" stroke="#AAABB3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.1942 26.4396L11.5076 21.7529" stroke="#AAABB3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.1942 10.5605V26.4397" stroke="#AAABB3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M19 33.9163C27.5144 33.9163 34.4167 27.0141 34.4167 18.4997C34.4167 9.98528 27.5144 3.08301 19 3.08301C10.4857 3.08301 3.58337 9.98528 3.58337 18.4997C3.58337 27.0141 10.4857 33.9163 19 33.9163Z"
        stroke="#AAABB3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default ExchangeIcon;
