import React, { useState, useEffect } from 'react';
import { Card, CardHeader, ListItem, ListItemIcon, Box, ListItemText, Typography, CardActions, SvgIcon, Button, Divider, Chip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PointIcon from '../Icon/PointIcon';
import DocumentOutlinedIcon from '../Icon/DocumentOutlinedIcon';
import { apiGetTotalLoyaltyPointsByUserId } from '../../../services/LoyaltyPointsService';

const ValidRewardCard = ({ data, index, sx, onClaim, status, onRedeem, onAddWishlist, ...props }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth?.auth);
  const [totalLoyaltyPoints, setTotalLoyaltyPoints] = useState(0);

  const getTotalLoyaltyPoints = async () => {
    const res = await apiGetTotalLoyaltyPointsByUserId(auth?.user?.id);
    if (res && !res.error) {
      setTotalLoyaltyPoints(res.data.total_points);
    }
  };

  useEffect(() => {
    getTotalLoyaltyPoints();
  }, [auth?.user.id]);

  return (
    <Box sx={[{ overflow: 'hidden' }, sx]} {...props}>
      <Card sx={{ mb: 2 }}>
        <CardHeader
          title="Valid Reward"
          action={
            <ListItem disablePadding>
              <PointIcon width={24} height={24} />
              <ListItemText primaryTypographyProps={{ color: 'grey.700' }} primary={'Your Points: '} />
              <Typography component="span" color="inehrit">
                {totalLoyaltyPoints}
              </Typography>
            </ListItem>
          }
        />
        <CardHeader
          title={data?.title}
          sx={{ pt: 0 }}
          titleTypographyProps={{ color: 'grey.700' }}
          avatar={<DocumentOutlinedIcon />}
          action={
            <ListItem disablePadding>
              <PointIcon />
              <ListItemText primaryTypographyProps={{ color: 'error' }} primary={-data?.price} />
            </ListItem>
          }
        />
      </Card>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '-15px',
          width: 'calc(100% + 30px)',
          my: '-30px',
        }}
      >
        <Box
          sx={{
            bgcolor: '#f7f1e3',
            border: '1px solid #E6E7EB',
            width: 30,
            height: 30,
            borderRadius: 30,
          }}
        />
        <Divider sx={{ border: '0.5px dashed #E6E7EB', flex: 1 }} />
        <Box
          sx={{
            bgcolor: '#f7f1e3',
            border: '1px solid #E6E7EB',
            width: 30,
            height: 30,
            borderRadius: 30,
          }}
        />
      </Box>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '10px',
          border: '1px solid #E6E7EB',
          borderTop: 'none',
          zIndex: 500,
          pt: '30px',
        }}
        key={index}
      >
        <CardActions>
          {status?.active_reward?.id || status?.wishlist?.id ? (
            <ListItem sx={{ flex: 1 }}>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="success" />
              </ListItemIcon>
              <ListItemText sx={{ color: 'green' }}>{status?.active_reward?.id ? 'Got this Reward' : status?.wishlist?.id ? 'Added to Wishlist' : ''}</ListItemText>
            </ListItem>
          ) : (
            <Button variant="outlined" sx={{ flex: 1 }} onClick={onAddWishlist}>
              {'Add to Wishlist'}
            </Button>
          )}
          {status?.active_reward?.id ? (
            status?.active_reward?.is_redeemed ? (
              <ListItem sx={{ flex: 1 }}>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="success" />
                </ListItemIcon>
                <ListItemText sx={{ color: 'green' }}>{'Redeemed'}</ListItemText>
              </ListItem>
            ) : (
              <Button variant="contained" sx={{ flex: 1 }} onClick={onRedeem}>
                {'Redeem'}
              </Button>
            )
          ) : (
            <Button variant="contained" sx={{ flex: 1 }} onClick={onClaim}>
              {'Claim'}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};
export default ValidRewardCard;
