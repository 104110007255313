import { query, jsonQuery } from './common';

export const apiGetGroupsByUserId = (userId, searchParams) => query(`/users/${userId}/groups`, { searchParams });
export const apiGetGroupMembersById = (id, searchParams) => query(`/groups/${id}/members`, { searchParams });
export const apiGetGroupPreviousRestaurants = (id, searchParams) => query(`/groups/${id}/previous_restaurants`, { searchParams });
export const apiGetGroupRecommendedRestaurants = (id, searchParams) => query(`/groups/${id}/recommended_restaurants`, { searchParams });
export const apiGetGroupById = (id) => query(`/groups/${id}`);
export const apiCreateGroup = (data) => jsonQuery(`/groups`, 'POST', data);
export const apiAddGroupMembers = (id, data) => jsonQuery(`/groups/${id}/members`, 'POST', data);
export const apiRemoveGroupMember = (group_id, member_id) => jsonQuery(`/groups/${group_id}/members/${member_id}`, 'DELETE');
