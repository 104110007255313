import axiosInstance from '../services/AxiosInstance';
import { query, jsonQuery } from './common';

export const apiGetUsers = (searchParams) => query(`/users`, { searchParams });
export const apiCreateUserPreference = (id, data) => jsonQuery(`/users/${id}/preference`, 'POST', data);
export const apiGetUserPreference = (id, searchParams) => query(`/users/${id}/preference`, { searchParams });
export const apiGetUserReviews = (id, searchParams) => query(`/users/${id}/reviews`, { searchParams });
export const apiGetUserById = (id) => query(`/users/${id}`);
export const apiGetUserEvents = (id, searchParams) => query(`/users/${id}/events`, { searchParams });
export const apiGetUserNotifications = (id, searchParams) => query(`/users/${id}/notifications`, { searchParams });
export const apiGetUserRequestedEvents = (id, searchParams) => query(`/users/${id}/requested_events`, { searchParams });
export const apiGetUserActiveRewards = (id, searchParams) => query(`/users/${id}/active_rewards`, { searchParams });
export const apiGetUserWishlists = (id, searchParams) => query(`/users/${id}/wishlists`, { searchParams });
export const apiGetUserRecommendedRestaurants = (id, searchParams) => query(`/users/${id}/recommended_restaurants`, { searchParams });
export const apiGetUserRewardStatus = (id, reward_id) => query(`/users/${id}/rewards/${reward_id}/status`);
export const apiUpdateUserById = (id, data) => jsonQuery(`/users/${id}`, 'PUT', data);
export const apiCreateUserGroup = (id, data) => jsonQuery(`/users/${id}/groups`, 'POST', data);
export const apiJoinUserGroup = (id, code) => jsonQuery(`/users/${id}/join_group/${code}`, 'POST');
export const apiSendInvitation = (id, data) => jsonQuery(`/users/${id}/send_invitation`, 'POST', data);

export function getPosts() {
  return axiosInstance.get(`posts.json`);
}

export function createPost(postData) {
  return axiosInstance.post(`posts.json`, postData);
}

export function updatePost(post, postId) {
  return axiosInstance.put(`posts/${postId}.json`, post);
}

export function deletePost(postId) {
  return axiosInstance.delete(`posts/${postId}.json`);
}

export function formatPosts(postsData) {
  let posts = [];
  for (let key in postsData) {
    posts.push({ ...postsData[key], id: key });
  }

  return posts;
}
