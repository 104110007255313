import React, { useState, useEffect } from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemIcon, DialogActions, Dialog, Button, DialogTitle, DialogContent, ListItemText, SvgIcon, Box, Divider, Typography, AvatarGroup } from '@mui/material';
import { find, get } from 'lodash';
import GroupAvatar from '../Avatar/GroupAvatar';
import { apiDeleteEvent, apiParticipateEventUser, apiGetEvent } from '../../../services/EventService';
import { getCurrentOpeningParams } from '../../../utils/dateTimeUtils';
import LocationIcon from '../Icon/LocationIcon';
import RestaurantCardContent from '../Card/RestaurantCardContent';
import StatusAvatar from '../Avatar/StatusAvatar';
import { apiDeleteEventParticipant } from '../../../services/EventParticipantService';
import Information from '../Dashboard/Restaurant/Information';
import UserAvatar from '../Avatar/UserAvatar';

const ACTION_BUTTONS = [
  { value: 'yes', label: 'Yes', color: 'success' },
  { value: 'on_my_way', label: 'On my way', color: 'primary' },
  { value: 'no', label: 'No', color: 'error' },
];

const EventDialog = ({ open, onClose, data, user }) => {
  const [event, setEvent] = useState(data);
  const participation = find(event.participants, (participant) => participant.user_id === user.id);

  const handleParticipate = async (status) => {
    if (status === participation.status) {
      const resEventParticipant = await apiDeleteEventParticipant(participation.id);
      if (resEventParticipant && !resEventParticipant.error) {
        getEvent();
      }
    }
    const resEventParticipant = await apiDeleteEventParticipant(event.id, user?.id, { status });
    if (resEventParticipant && !resEventParticipant.error) {
      getEvent();
    }
  };

  const handleDeleteEvent = async () => {
    const resEvent = await apiDeleteEvent(event.id);
    if (resEvent && !resEvent.error) {
      onClose();
    }
  };
  const getEvent = async () => {
    setEvent(data);
    const resEvent = await apiGetEvent(data?.id);
    if (resEvent && !resEvent.error) {
      setEvent(resEvent.data);
    }
  };

  useEffect(() => {
    data.id && getEvent(data);
  }, [data.id]);

  return (
    <Dialog maxWidth={'sm'} open={open} onClose={onClose}>
      <DialogTitle>{event?.restaurant?.name}</DialogTitle>
      <DialogContent>
        <RestaurantCardContent data={event?.restaurant} />
        <Information data={event?.restaurant} />
        <Divider />
        <ListItem>
          <ListItemAvatar sx={{ mr: 2 }}>
            <GroupAvatar size="large" showLabel={false} data={event?.group} />
          </ListItemAvatar>
          <ListItemText
            alignItems="flex-start"
            primary={event?.group?.name}
            primaryTypographyProps={{ color: 'text.secondary', variant: 'h5' }}
            secondary={
              event?.group?.members?.length > 0 && (
                <AvatarGroup sx={{ justifyContent: 'flex-end' }} max={3}>
                  {event?.group?.members.map((member) => (
                    <Box>
                      <UserAvatar data={member} />
                      <StatusAvatar
                        sx={{ my: -1 }}
                        status={get(
                          find(event?.participants, (participant) => participant?.user_id === member?.id),
                          'status'
                        )}
                      />
                    </Box>
                  ))}
                </AvatarGroup>
              )
            }
          />
        </ListItem>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Typography>Going?</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {ACTION_BUTTONS.map((button) => (
            <Button
              variant={participation?.status === button.value ? 'contained' : 'outlined'}
              color={participation?.status === button.value ? button.color : 'primary'}
              onClick={() => handleParticipate(button.value)}
            >
              {button.label}
            </Button>
          ))}
        </Box>
      </DialogActions>
      {data?.author_id === user?.id && (
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={handleDeleteEvent}>
            {'Delete this event'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default EventDialog;
