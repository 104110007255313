const styles = (theme) => ({
  avatarRoot: {
    border: '1.5px solid #FFFFFF',
  },
  smallSize: {
    width: 30,
    height: 30,
    fontSize: 14,
  },
  largeSize: {
    width: 65,
    height: 65,
    fontSize: 20,
  },
  lightBlueColor: {
    backgroundColor: '#FFC7B6',
    color: '#BA1919',
  },
  blueColor: {
    backgroundColor: '#7B61FF',
    color: '#FFF',
  },
  lightYellowColor: {
    backgroundColor: '#FFD897',
    color: '#986E00',
  },
  lightGreenColor: {
    backgroundColor: '#E2EAE2',
    color: '#006D01',
  },
  lightBlueColor: {
    backgroundColor: '#E2CEFC',
    color: '#5400C0',
  },
  lightGreyColor: {
    backgroundColor: '#D9D9D9',
    color: '#032E03',
  },
});

export default styles;
