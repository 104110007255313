import { last, split, findIndex, get, isEmpty, xorWith, isEqual } from 'lodash';

export const getFullName = (obj, prefix = '') => {
  if (!obj) return '';

  if (obj.full_name) return prefix + obj.full_name;

  if (obj.name) return prefix + obj.name;
  let fullName = '';

  if (obj.first_name) {
    fullName += obj.first_name;
  }

  if (obj.last_name) {
    fullName = fullName + ' ' + obj.last_name;
  }

  return prefix + fullName;
};

export const getInitialName = (fullName) => {
  if (!fullName) {
    return '';
  }
  var names = fullName.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const getContentRange = (string) => {
  return last(split(string, '/')) || 0;
};

export const updateElementInArray = (array, newElement, key = 'id', change = true, elementId) => {
  if (!Array.isArray(array)) {
    return [];
  }
  const resultArray = [...array];

  const index = findIndex(array, (ele) => Number(elementId || get(newElement, key)) === Number(get(ele, key)));

  if (index >= 0) {
    if (change || typeof newElement !== 'object') {
      resultArray[index] = newElement;
    } else {
      resultArray[index] = { ...resultArray[index], ...newElement };
    }
  } else {
    resultArray.push(newElement);
  }

  return resultArray;
};
