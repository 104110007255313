import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { apiGetUsers } from '../../../services/UsersService';
import UserListItem from '../List/UserListItem';

const UsersDialog = ({ open, onClose, onSelectUser }) => {
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    const resUsers = await apiGetUsers();
    if (resUsers && !resUsers.error) {
      setUsers(resUsers.data);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Dialog maxWidth={'xs'} open={open} onClose={onClose}>
      <DialogTitle>{'Who do you want to send the reward to?'}</DialogTitle>
      <DialogContent>
        {users.map((user) => (
          <UserListItem button data={user} onClick={() => onSelectUser(user)} />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default UsersDialog;
