import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const PersonOutlinedIcon = ({ fill = 'transparent', ...props }) => {
  return (
    <SvgIcon width="33" height="33" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2786 30.9993C6.58702 30.9993 1.72656 30.1136 1.72656 26.5666C1.72656 23.0195 6.55618 19.7451 12.2786 19.7451C17.9701 19.7451 22.8306 22.9878 22.8306 26.5348C22.8306 30.0804 18.001 30.9993 12.2786 30.9993Z"
        stroke="currentcolor"
        fill={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2675 14.8297C16.0025 14.8297 19.0298 11.8025 19.0298 8.06742C19.0298 4.33239 16.0025 1.30371 12.2675 1.30371C8.53241 1.30371 5.5037 4.33239 5.5037 8.06742C5.49113 11.7898 8.49738 14.8171 12.2198 14.8297C12.2366 14.8297 12.252 14.8297 12.2675 14.8297Z"
        stroke="currentcolor"
        fill={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
export default PersonOutlinedIcon;
