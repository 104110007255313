import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { Autoplay } from 'swiper';
import GroupAvatar from '../../Avatar/GroupAvatar';
import AddIcon from '@mui/icons-material/Add';
import 'swiper/css';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { apiGetGroupsByUserId } from '../../../../services/GroupService';

const CategorySlider = () => {
  const [groups, setGroups] = useState([]);
  const auth = useSelector((state) => state.auth?.auth);

  const getGroups = async () => {
    const resGroups = await apiGetGroupsByUserId(auth?.user?.id, { range: [0, 4], sort: ['updated_at', 'DESC'] });
    if (resGroups && !resGroups.error) {
      setGroups(resGroups.data);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);
  return (
    <>
      <Swiper
        className="mySwiper-2"
        speed={1200}
        slidesPerView={8}
        // spaceBetween= {20}
        //loop={true}
        autoplay={{
          delay: 1200,
        }}
        modules={[Autoplay]}
        // breakpoints = {{
        // 	360: {
        //         slidesPerView: 2,
        //         spaceBetween: 20,
        //     },
        //     600: {
        //         slidesPerView: 3,
        //         spaceBetween: 20,
        //     },
        //     768: {
        //         slidesPerView: 4,
        //         spaceBetween: 20,
        //     },
        //     1200: {
        //         slidesPerView: 3,
        //         spaceBetween: 20,
        //     },
        //     1920: {

        //         slidesPerView: 5,
        //         spaceBetween: 20,
        //     },
        // }}
      >
        <SwiperSlide>
          <IconButton
            component={Link}
            to="/groups/create"
            sx={{
              height: 60,
              width: 60,
              border: '1.5px solid #FFFFFF',
              boxShadow: '0px 4px 8px -2px rgba(30, 60, 120, 0.1), 0px 2px 4px -2px rgba(30, 60, 120, 0.06)',
            }}
          >
            <AddIcon />
          </IconButton>
        </SwiperSlide>
        {groups.map((group) => (
          <SwiperSlide>
            <GroupAvatar data={group} key={group.id} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
export default CategorySlider;
