import React from 'react';
import { ListItemText, ListItem, ListItemAvatar, Avatar, Typography } from '@mui/material';

const MenuListItem = ({ data, ...props }) => {
  return (
    <ListItem variant="outlined" sx={{borderRadius: '5px'}} {...props}>
      <ListItemAvatar sx={{ mr: 1 }}>
        <Avatar sx={{ width: 84, height: 84 }} variant="rounded" src={data.image || require('../../../images/resturent-review/yogaimage.png')} />
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ variant: 'h6', color: 'text.primary' }}
        secondaryTypographyProps={{ variant: 'body1', color: 'grey.700' }}
        primary={data.name}
        secondary={data.description}
      />
      <Typography color="text.primary" variant="h5">{`$${data.price}`}</Typography>
    </ListItem>
  );
};
export default MenuListItem;
