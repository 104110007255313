import React from 'react';
import { SvgIcon } from '@mui/material';

const ClockOutlinedIcon = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 26" {...props}>
      <path
        d="M23.8333 13.0003C23.8333 18.9803 18.98 23.8337 13 23.8337C7.01996 23.8337 2.16663 18.9803 2.16663 13.0003C2.16663 7.02033 7.01996 2.16699 13 2.16699C18.98 2.16699 23.8333 7.02033 23.8333 13.0003Z"
        stroke="currentcolor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0191 16.4449L13.6608 14.4407C13.0758 14.0941 12.5991 13.2599 12.5991 12.5774V8.13574"
        stroke="currentcolor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
export default ClockOutlinedIcon;
