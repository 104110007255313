import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const CalendarIcon = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 13 13" {...props}>
      <path d="M4.3335 1.08301V2.70801" stroke="currentColor" fill="transparent" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.6665 1.08301V2.70801" stroke="currentColor" fill="transparent" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.896 4.92383H11.1043" stroke="currentColor" fill="transparent" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M11.375 4.60384V9.20801C11.375 10.833 10.5625 11.9163 8.66667 11.9163H4.33333C2.4375 11.9163 1.625 10.833 1.625 9.20801V4.60384C1.625 2.97884 2.4375 1.89551 4.33333 1.89551H8.66667C10.5625 1.89551 11.375 2.97884 11.375 4.60384Z"
        stroke="currentColor"
        fill="transparent"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M8.50126 7.42122H8.50612" stroke="currentColor" fill="transparent" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.50126 9.04622H8.50612" stroke="currentColor" fill="transparent" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.49735 7.42122H6.50222" stroke="currentColor" fill="transparent" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.49735 9.04622H6.50222" stroke="currentColor" fill="transparent" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.49296 7.42122H4.49782" stroke="currentColor" fill="transparent" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.49296 9.04622H4.49782" stroke="currentColor" fill="transparent" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
    </SvgIcon>
  );
};
export default CalendarIcon;
