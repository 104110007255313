import React from 'react';
import { SvgIcon } from '@mui/material';

const OutlinedCircleIcon = ({ ...props }) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="11.5" stroke="currentColor" fill="transparent" />
    </SvgIcon>
  );
};
export default OutlinedCircleIcon;
