import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const SendIcon = ({ ...props }) => {
  return (
    <SvgIcon width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.62505 3.9501L9.9313 2.18135C12.3125 1.3876 13.6063 2.6876 12.8188 5.06885L11.05 10.3751C9.86255 13.9438 7.91255 13.9438 6.72505 10.3751L6.20005 8.8001L4.62505 8.2751C1.0563 7.0876 1.0563 5.14385 4.62505 3.9501Z"
        stroke="currentColor"
        fill="transparent"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M6.31873 8.53086L8.55623 6.28711" stroke="currentColor" fill="transparent" stroke-linecap="round" stroke-linejoin="round" />
    </SvgIcon>
  );
};
export default SendIcon;
