import React from 'react';
import { Card, CardContent, CardMedia, ListItem, ListItemIcon, Box, ListItemText, Typography, CardActions, SvgIcon, Button, Divider, Chip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { isFunction } from 'lodash';
import LocationIcon from '../Icon/LocationIcon';
import StarIcon from '../Icon/StarIcon';
import PointIcon from '../Icon/PointIcon';
const RewardCard = ({ data, index, onDelete, ActionChip }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '10px',
          border: '1px solid #E6E7EB',
          borderBottom: 'none',
          overflow: 'hidden',
        }}
        key={index}
      >
        <Box sx={{ position: 'relative' }}>
          {isFunction(onDelete) && (
            <Chip label="Remove" onClick={onDelete} icon={<DeleteIcon color="error" />} sx={{ color: 'red', position: 'absolute', top: 10, left: 10, zIndex: 200, bgcolor: '#FFF' }} />
          )}
          {ActionChip && ActionChip}
          <CardMedia component="img" src={data?.restaurant?.image || require('../../../images/resturent-review/yogaimage.png')} sx={{ height: 170 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              position: 'absolute',
              bottom: 0,
              p: 1,
              width: 1 / 1,
            }}
          >
            <ListItem disablePadding sx={{ color: '#FFF', width: 'fit-content' }}>
              <ListItemIcon>
                <StarIcon rating={data?.restaurant?.rating || 0} />
              </ListItemIcon>
              <ListItemText primary={data?.restaurant?.rating || 0} sx={{ my: 0 }} />
            </ListItem>
          </Box>
        </Box>
        <CardContent>
          <Typography gutterBottom noWrap>
            {data?.restaurant?.name}
          </Typography>
          <ListItem disablePadding alignItems="flex-start" sx={{ color: 'grey.700' }}>
            <LocationIcon sx={{ fontSize: '1.25rem' }} />
            <Typography variant="body2" color="inherit" noWrap>
              {data?.restaurant?.address}
            </Typography>
          </ListItem>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '-15px',
          width: 'calc(100% + 30px)',
          my: '-15px',
        }}
      >
        <Box
          sx={{
            bgcolor: '#f7f1e3',
            border: '1px solid #E6E7EB',
            width: 30,
            height: 30,
            borderRadius: 30,
          }}
        />
        <Divider sx={{ border: '0.5px dashed #E6E7EB', flex: 1 }} />
        <Box
          sx={{
            bgcolor: '#f7f1e3',
            border: '1px solid #E6E7EB',
            width: 30,
            height: 30,
            borderRadius: 30,
          }}
        />
      </Box>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '10px',
          border: '1px solid #E6E7EB',
          borderTop: 'none',
          zIndex: 500,
        }}
        key={index}
      >
        <CardContent>
          <Typography variant="subtitle1" component="h3" color="primary">
            {data?.title}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <ListItem disablePadding>
              <PointIcon />
              <Typography variant="subtitle2" color="error.main">
                {data?.price}
              </Typography>
            </ListItem>
            <Button variant="text" size="small" onClick={() => navigate(`/rewards/${data.id}`, { state: data })} endIcon={<ArrowForwardIosIcon />}>
              {'View'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default RewardCard;
