import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Unstable_Grid2 as Grid, Container } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import EventCard from '../Card/EventCard';
import { get, split, last } from 'lodash';
import { apiGetEvents } from '../../../services/EventService';
import EventDialog from '../Dialog/EventDialog';
import { useSelector } from 'react-redux';
import { apiGetUserRequestedEvents } from '../../../services/UsersService';

const Events = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth?.auth);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_count: 0,
    per_page: 12,
  });
  const searchKey = location?.state?.searchKey;

  const getEvents = async () => {
    const range = [(pagination.current_page - 1) * pagination.per_page, pagination.current_page * pagination.per_page - 1];

    const resEvents = await apiGetUserRequestedEvents(auth?.user?.id, { range });
    if (resEvents && !resEvents.error) {
      const total_count = Number(last(split(get(resEvents, 'headers.content-range'), '/')) || 0);
      setEvents(resEvents.data);
      setPagination({ ...pagination, total_count });
    }
  };

  useEffect(() => {
    getEvents();
  }, [searchKey, pagination.current_page]);

  return (
    <Container >
      <Grid container spacing={2}>
        {events.map((item) => (
          <Grid md={3} sm={4} xs={6}>
            <EventCard data={item} key={item.id} onClick={() => setSelectedEvent(item)} />
          </Grid>
        ))}
      </Grid>
      <Grid component="nav" container justifyContent="center" sx={{ py: 2 }}>
        <Pagination count={Math.ceil(pagination.total_count / pagination.per_page)} page={pagination.current_page} onChange={(_, page) => setPagination({ ...pagination, current_page: page })} />
      </Grid>
      {selectedEvent?.id && <EventDialog data={selectedEvent} open={selectedEvent?.id} user={auth?.user} onClose={() => setSelectedEvent(null)} />}
    </Container>
  );
};
export default Events;
