import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';

const StarIcon = ({ rating=0, ...props }) => {
  const percentage = (rating / 5) * 100;
  const tpath = "M10.3689 2.67264L11.7408 5.435C11.9257 5.81703 12.422 6.17947 12.8404 6.25784L15.3216 6.66925C16.9077 6.93373 17.2774 8.08962 16.139 9.2455L14.2026 11.1948C13.8815 11.5181 13.6966 12.1548 13.8037 12.6152L14.3583 15.0249C14.7962 16.9253 13.7842 17.6697 12.1203 16.6706L9.79476 15.2796C9.37635 15.0249 8.67576 15.0249 8.25736 15.2796L5.93179 16.6706C4.2679 17.6599 3.25593 16.9253 3.6938 15.0249L4.24844 12.6152C4.33601 12.145 4.15113 11.5083 3.83003 11.185L1.89368 9.23571C0.755223 8.08962 1.12498 6.93373 2.71103 6.65946L5.19228 6.24804C5.61069 6.17947 6.10694 5.80724 6.29182 5.42521L7.6638 2.66284C8.41304 1.17391 9.61961 1.17391 10.3689 2.67264Z";
  return (
    <div style={{ position: 'relative', width: 18, height: 19 }}>
      <SvgIcon width={18} height={19} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d={tpath} fill={'#00000035'} />
      </SvgIcon>
      <SvgIcon width={18} height={19} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, left: 0, clipPath: `inset(0 ${100 - percentage}% 0 0)` }}>
        <path d={tpath} fill={'currentColor'} />
      </SvgIcon>
    </div>
  );
};
export default StarIcon;
