import React from 'react';
import { Card, CardActionArea } from '@mui/material';
import UserAvatarGroup from '../Avatar/UserAvatarGroup';
import GroupAvatar from '../Avatar/GroupAvatar';

const GroupCard = ({ data, onClick }) => {
  return (
    <Card sx={{ width: 1 / 1, height: 1 / 1, display: 'flex' }}>
      <CardActionArea onClick={onClick} sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <GroupAvatar size='large' data={data} />
        {data?.members.length > 0 && <UserAvatarGroup size="small" data={data?.members} />}
      </CardActionArea>
    </Card>
  );
};
export default GroupCard;
