import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { apiGetEvents } from '../../../../services/EventService';
import EventCard from '../../Card/EventCard';
import EventDialog from '../../Dialog/EventDialog';
import { useSelector } from 'react-redux';
import { apiGetUserEvents } from '../../../../services/UsersService';

const PopularDishesSlider = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const auth = useSelector((state) => state.auth?.auth);

  const getEvents = async () => {
    const resEvents = await apiGetUserEvents(auth?.user.id, { range: [0, 3], sort: 'updated_at' });
    if (resEvents && !resEvents.error) {
      setEvents(resEvents.data);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <Swiper
        className="mySwiper-3"
        speed={1500}
        slidesPerView={4}
        spaceBetween={30}
        loop={false}
        autoplay={{
          delay: 2000,
        }}
        modules={[Autoplay]}
        breakpoints={{
          360: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {events.map((data, index) => (
          <SwiperSlide key={index}>
            <EventCard data={data} onClick={() => setSelectedEvent(data)} />
          </SwiperSlide>
        ))}
      </Swiper>
      {selectedEvent?.id && <EventDialog data={selectedEvent} open={selectedEvent?.id} user={auth?.user} onClose={() => setSelectedEvent(null)} />}
    </>
  );
};
export default PopularDishesSlider;
