import React, { useEffect, useState } from 'react';
import { Typography, Tab, Tabs, ListItemText, ListItem, Box, List, ListItemAvatar, Avatar, Card } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router';
import { isArray, get } from 'lodash';
import { getFullName, getInitialName } from '../../../../utils/dataUtils';
import MenuListItem from '../../List/MenuListItem';

const Menus = ({ data }) => {
  const [menuTabIndex, setMenuTabIndex] = useState(0);
  const menus = get(data, `${menuTabIndex}.menus`);

  return (
    <Box>
      <Tabs value={menuTabIndex} onChange={(_, value) => setMenuTabIndex(value)} aria-label="lab API tabs example" variant="scrollable"
        scrollButtons="auto">
        {isArray(data) &&
          data.map((category, index) => (
            <Tab
              value={index}
              label={category.category}
              sx={{
                color: '#AAABB3',
                p: 1,
                fontSize: 20,
                zIndex: 10,
                '&.Mui-selected': {
                  color: '#2D264B',
                },
              }}
            />
          ))}
      </Tabs>
      <List>{isArray(menus) && menus.map((menu) => <MenuListItem data={menu} />)}</List>
    </Box>
  );
};
export default Menus;
