import React, { useState, useEffect } from 'react';
import { Button, CardActions, TextField, Container, OutlinedInput, Step, Stepper, StepButton, Box, Card, CardContent, Alert, Unstable_Grid2 as Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import GroupCard from '../Card/GroupCard';
import { apiGetGroupsByUserId } from '../../../services/GroupService';
import { apiCreateEvent } from '../../../services/EventService';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const steps = ['Select a group', 'Create Event', 'Finish'];

const CreateEvent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth?.auth);
  const [activeStep, setActiveStep] = useState(0);
  const [searchKey, setSearchKey] = useState();
  const [groups, setGroups] = useState([]);

  const initialValues = {
    date: '',
    note: '',
    author_id: auth?.user.id,
    restaurant_id: location?.state?.id,
  };
  const validationSchema = Yup.object({
    group_id: Yup.number().required('Required'),
    restaurant_id: Yup.number().required('Required'),
    author_id: Yup.number().required('Required'),
    date: Yup.string().required('Required'),
    note: Yup.string().required('Required'),
  });

  const handleSelectGroup = (group) => {
    formik.setFieldValue('group_id', group.id);
    setActiveStep(activeStep + 1);
  };

  const createEvent = async (data) => {
    const resEvent = await apiCreateEvent(data);
    if (resEvent && !resEvent.error) {
      setActiveStep(activeStep + 1);
    }
  };

  const getGroups = async () => {
    const resGroups = await apiGetGroupsByUserId(auth?.user?.id, { search_key: searchKey });
    if (resGroups && !resGroups.error) {
      setGroups(resGroups.data);
    }
  };

  useEffect(() => {
    getGroups();
  }, [searchKey]);

  const formik = useFormik({
    initialValues,
    onSubmit: createEvent,
    validationSchema,
  });

  const renderView = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <OutlinedInput id="search_key" fullWidth sx={{ my: 2 }} startAdornment={<SearchIcon />} value={searchKey} placeholder="Search groups" onChange={(e) => setSearchKey(e.target.value)} />
            <Grid container spacing={2}>
              {groups.map((group) => (
                <Grid item xs={6} sm={4}>
                  <GroupCard data={group} onClick={() => handleSelectGroup(group)} />
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Card>
            <CardContent>
              <TextField
                label="Date"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange('date')}
                id="date"
                name="date"
                fullWidth
                margin="normal"
                error={formik.touched.date && formik.errors.date}
                helperText={formik.touched.date && formik.errors.date}
              />
              <TextField
                id="note"
                multiline
                number
                label="Note"
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange('note')}
                name="note"
                margin="normal"
                fullWidth
                minRows={5}
                error={formik.touched.note && formik.errors.note}
                helperText={formik.touched.note && formik.errors.note}
              />
            </CardContent>
            <CardActions>
              <Button variant="contained" onClick={formik.handleSubmit} fullWidth>
                {'Create & Notify Group'}
              </Button>
            </CardActions>
          </Card>
        );
      case 2:
        return <Alert severity="success">Your event submited successfully.!</Alert>;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Stepper nonLinear activeStep={activeStep} sx={{ mb: 2 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton color="inherit">{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      {renderView()}
    </Container>
  );
};
export default CreateEvent;
