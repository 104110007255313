import React from 'react';
import { SvgIcon } from '@mui/material';

const RoundedCheckIcon = ({ ...props }) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24"  {...props}>
      <circle cx="12" cy="12" r="12" fill="currentcolor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3548 8.57963C17.7943 9.01913 17.7943 9.73163 17.3548 10.1711L11.5078 16.0196C11.3398 16.1888 11.1229 16.2985 10.89 16.3356L10.7128 16.3496C10.4143 16.3496 10.1278 16.2311 9.91631 16.0196L7.07831 13.1786C6.64031 12.7391 6.64031 12.0251 7.07981 11.5871C7.51781 11.1476 8.23181 11.1476 8.67131 11.5871L10.7128 13.6316L15.7648 8.57963C16.2043 8.14013 16.9153 8.14013 17.3548 8.57963Z"
        fill="white"
      />
    </SvgIcon>
  );
};
export default RoundedCheckIcon;
