import axios from 'axios';
import { restApiSettings } from '../config/api';
import { staticStore as store } from '../store/store';

const axiosInstance = axios.create({
  baseURL: `${restApiSettings.baseURL}/v1/`,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.access_token;
  config.params = config.params || {};
  config.params['Authorization'] = token;
  return config;
});

export default axiosInstance;
