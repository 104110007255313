import React from 'react';
import Dialog from '@mui/material/Dialog';
import Loading from '../Loading';

const styles = {
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: '#f8f8f8ad',
  },
  loader: {
    top: 'calc(50% - 45px)',
    left: 'calc(50% - 45px)',
    zIndex: 1000,
    position: 'absolute',
  }
};

const FullScreenLoading = ({ loading }) => {
  return (
  <Dialog open={loading}>
    <Loading />
  </Dialog>
)};

export default FullScreenLoading;
