/**
 * Create the store with dynamic reducers
 */

import { applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import createReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';

const persistConfig = { key: 'root', storage };

export let staticStore = null;

export default (preloadedState = {}, history) => {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [routerMiddleware(history), thunk];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
        })
      : compose;
  /* eslint-enable */
  const store = configureStore({
    reducer: persistReducer(persistConfig, createReducer()),
    preloadedState,
    // enhancers: composeEnhancers(...enhancers),
    middleware: middlewares,
  });

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(
        persistReducer(persistConfig, createReducer(store.injectedReducers))
      );
    });
  }

   const persistor = persistStore(store);
  staticStore = store;
  return { store, persistor };
};
