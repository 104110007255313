import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQPage = ({ ...props }) => {
  const contents = [
    {
      title: 'FAQPage',
      description:
        'In order to earn points you can do some activities like creating groups, creating events, add review on restaurants etc...',
    },
    {
      title: 'What are the benefits?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'How can you get a reward?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'What can you create an event?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'How can you create a group?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'How can you buy points?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
  ];
  const [accordionIndex, setAccordionIndex] = useState();

  return (
    <Container sx={{ pb: 12 }}>
      {contents.map((content, index) => (
        <Accordion
          square
          expanded={accordionIndex === index}
          onChange={(_, isExpanded) => {
            setAccordionIndex(isExpanded ? index : false);
          }}
          elevation={0}
          sx={{ mt: 3.2 }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography variant="subtitle2" component="h2" color="primary" fontWeight="bold">
              {content.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{content.description}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

FAQPage.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
};

FAQPage.defaultProps = {
  data: {},
};

export default FAQPage;
