import React from 'react';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';


function PhoneNumberTextField(props) {
  const { onChange, onBlur, value, name, ...rest } = props;
  const [error, setError] = React.useState(null);

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }

    // Check if the input value is a valid US phone number
    const valid = /^\+1\s\d{3}\s\d{3}\s\d{4}$/.test(e.target.value);
    if (!valid) {
      // If the input value is not a valid US phone number, set an error message
      setError('Please enter a valid US phone number');
    }
  };

  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }

    // Clear the error message when the input value changes
    setError(null);
  };

  return (
    <InputMask
      mask="+1 999 999 9999"
      maskChar=" "
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      {...rest}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          {...rest}
          type="tel"
          name={name}
        />
      )}
    </InputMask>
  );
}

export default PhoneNumberTextField;