import React from 'react';
import { ListItemText, ListItem, ListItemAvatar, Avatar, ListItemIcon } from '@mui/material';
import { getFullName, getInitialName } from '../../../utils/dataUtils';
import StarIcon from '../Icon/StarIcon';
import UserAvatar from '../Avatar/UserAvatar';

const ReviewListItem = ({ data, ...props }) => {
  return (
    <ListItem variant="outlined" alignItems="flex-start" {...props}>
      <ListItemAvatar>
        <UserAvatar data={data?.author} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <ListItem disablePadding>
            <ListItemText primaryTypographyProps={{ color: 'text.secondary' }} primary={getFullName(data.author)} />
            <ListItem disablePadding sx={{ color: 'currency.main', width: 'fit-content' }}>
              <ListItemIcon>
                <StarIcon rating={data?.rating || 0} />
              </ListItemIcon>
              <ListItemText primary={data?.rating || 0} sx={{ my: 0 }} />
            </ListItem>
          </ListItem>
        }
        secondaryTypographyProps={{ color: 'grey.700' }}
        secondary={data.comment}
      />
    </ListItem>
  );
};
export default ReviewListItem;
