import React from 'react';
import { Avatar, ListItem, Typography, ListItemText, Badge, ListItemAvatar } from '@mui/material';
import { formatDate } from '../../../utils/dateTimeUtils';
import UserAvatarGroup from '../Avatar/UserAvatarGroup';
import { getInitialName } from '../../../utils/dataUtils';

const NotificationListItem = ({ data, children, ...props }) => {
  let message = '';
  let secondary = '';
  const content = JSON.parse(data?.content);
  console.log('content---', content);

  switch (data?.type) {
    case 2:
      message = `${data?.sender?.first_name} invitet you to join ${content?.name || ''} group.`;
      secondary = <UserAvatarGroup data={content?.members} />;
      break;
    case 1:
      message = `${data?.sender?.first_name} added you to ${content?.note || ''} Event.`;
      secondary = <UserAvatarGroup data={content?.group?.members} />;
      break;
    case 3:
      message = `${data?.sender?.first_name} sent you a Reward Gift`;
      secondary = (
        <ListItem>
          <ListItemAvatar>
            <Avatar src={data?.reward?.restaurant?.image}>{getInitialName(data?.reward?.restaurant)}</Avatar>
          </ListItemAvatar>
          <ListItemText>{data?.reward?.restaurant?.name}</ListItemText>
        </ListItem>
      );
      break;
    default:
      break;
  }

  return (
    <ListItem alignItems="center" button sx={{ border: '1.15152px solid #E6E7EB', bgcolor: '#FFF', borderRadius:2 }} {...props}>
      <ListItemText
        primary={
          <ListItem>
            <ListItemText>{message}</ListItemText>
            <Badge color="error" variant="dot"></Badge>
          </ListItem>
        }
        secondary={
          <ListItem>
            <ListItemText>{secondary}</ListItemText>
            <Typography component="time">{formatDate(data.created_at)}</Typography>
          </ListItem>
        }
      />
      {children}
    </ListItem>
  );
};
export default NotificationListItem;
