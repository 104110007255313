import React, { useEffect, useState } from 'react';
import { Typography, Tab, Unstable_Grid2 as Grid, ListItemIcon, ListItemText, ListItem, Box, ListItemAvatar, Avatar, Container, Button, CardMedia } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { apiGetRestaurantById } from '../../../services/RestaurantsService';
import { getInitialName } from '../../../utils/dataUtils';
import Menus from './Restaurant/Menus';
import Reviews from './Restaurant/Reviews';
import Information from './Restaurant/Information';
import { apiGetRewardById } from '../../../services/RewardService';
import { apiCreateWishlist, apiDeleteWishlist } from '../../../services/WishlistService';
import ValidRewardCard from '../Card/ValidRewardCard';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import { apiCreateActiveReward, apiUpdateActiveRewardById } from '../../../services/ActiveRewardService';
import { apiGetUserRewardStatus } from '../../../services/UsersService';

const RewardDetail = () => {
  const routeParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth?.auth);
  const [reward, setReward] = useState(location.state || {});
  const [restaurant, setRestaurant] = useState(reward.restaurant);
  const [tabIndex, setTabIndex] = useState('1');
  const [rewardActionStatus, setRewardActionStatus] = useState();
  const [status, setStatus] = useState();

  const handleAddWishlist = async () => {
    const resWishlist = await apiCreateWishlist({ owner_id: auth?.user?.id, reward_id: reward?.id });
    if (resWishlist && !resWishlist.error) {
      setStatus({ ...status, wishlist: resWishlist.data });
    }
  };

  const handleReward = async () => {
    if (rewardActionStatus === 'CLAIM') {
      const resActiveReward = await apiCreateActiveReward({ owner_id: auth?.user?.id, reward_id: reward?.id });
      if (resActiveReward && !resActiveReward.error) {
        setStatus({ ...status, active_reward: resActiveReward.data });
      }
      if (status?.wishlist) {
        const resWishlist = await apiDeleteWishlist(status?.wishlist?.id);
        if (resWishlist && !resWishlist.error) {
          setStatus({ ...status, active_reward: resActiveReward.data, wishlist: undefined });
        }
      }
    } else if (rewardActionStatus === 'REDEEM') {
      const resActiveReward = await apiUpdateActiveRewardById(status?.active_reward?.id, { owner_id: auth?.user?.id, reward_id: reward?.id, is_redeemed: true });
      if (resActiveReward && !resActiveReward.error) {
        setStatus({ ...status, active_reward: resActiveReward.data });
      }
    }
  };

  const getRestaurant = async () => {
    const resRestaurant = await apiGetRestaurantById(reward.restaurant_id);
    if (resRestaurant && !resRestaurant.error) {
      setRestaurant(resRestaurant.data);
    }
  };

  const getReward = async () => {
    const resReward = await apiGetRewardById(routeParams.id);
    if (resReward && !resReward.error) {
      setReward(resReward.data);
    }
  };

  const getRewardStatus = async () => {
    const resStatus = await apiGetUserRewardStatus(auth?.user?.id, reward.id);
    if (resStatus && !resStatus.error) {
      setStatus(resStatus.data);
    }
  };

  useEffect(() => {
    getRestaurant();
  }, [reward.restaurant_id]);

  useEffect(() => {
    getReward();
    getRewardStatus();
  }, [routeParams?.id, auth?.user?.id]);

  return (
    <Container >
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <Typography variant="h4" component="h2">
            {restaurant.name}
          </Typography>
          <CardMedia component="img" src={restaurant.image || require('../../../images/resturent-review/yogaimage.png')} alt="" style={{ width: '100%', height: '35vh' }} />
          <ListItem sx={{ transform: 'translateY( -50% )' }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: 120,
                  height: 120,
                  border: '4px solid #F5F5F5',
                  fontSize: '2rem',
                }}
                src={restaurant.image}
              >
                {getInitialName(restaurant.name)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText sx={{ transform: 'translateY(-50%)' }}>
              <ListItem sx={{ color: '#FFF' }}>
                <ListItemIcon>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.38 12.0001L10.79 14.4201L15.62 9.58008" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M10.75 2.45031C11.44 1.86031 12.57 1.86031 13.27 2.45031L14.85 3.81031C15.15 4.07031 15.71 4.28031 16.11 4.28031H17.81C18.87 4.28031 19.74 5.15031 19.74 6.21031V7.91031C19.74 8.30031 19.95 8.87031 20.21 9.17031L21.57 10.7503C22.16 11.4403 22.16 12.5703 21.57 13.2703L20.21 14.8503C19.95 15.1503 19.74 15.7103 19.74 16.1103V17.8103C19.74 18.8703 18.87 19.7403 17.81 19.7403H16.11C15.72 19.7403 15.15 19.9503 14.85 20.2103L13.27 21.5703C12.58 22.1603 11.45 22.1603 10.75 21.5703L9.17 20.2103C8.87 19.9503 8.31 19.7403 7.91 19.7403H6.18C5.12 19.7403 4.25 18.8703 4.25 17.8103V16.1003C4.25 15.7103 4.04 15.1503 3.79 14.8503L2.44 13.2603C1.86 12.5703 1.86 11.4503 2.44 10.7603L3.79 9.17031C4.04 8.87031 4.25 8.31031 4.25 7.92031V6.20031C4.25 5.14031 5.12 4.27031 6.18 4.27031H7.91C8.3 4.27031 8.87 4.06031 9.17 3.80031L10.75 2.45031Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText> {'You have been here.'} </ListItemText>
                <Box>
                  <ListItem disablePadding sx={{ color: '#FFF', width: 'fit-content' }}>
                    <ListItemIcon>
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.3689 2.67264L11.7408 5.435C11.9257 5.81703 12.422 6.17947 12.8404 6.25784L15.3216 6.66925C16.9077 6.93373 17.2774 8.08962 16.139 9.2455L14.2026 11.1948C13.8815 11.5181 13.6966 12.1548 13.8037 12.6152L14.3583 15.0249C14.7962 16.9253 13.7842 17.6697 12.1203 16.6706L9.79476 15.2796C9.37635 15.0249 8.67576 15.0249 8.25736 15.2796L5.93179 16.6706C4.2679 17.6599 3.25593 16.9253 3.6938 15.0249L4.24844 12.6152C4.33601 12.145 4.15113 11.5083 3.83003 11.185L1.89368 9.23571C0.755223 8.08962 1.12498 6.93373 2.71103 6.65946L5.19228 6.24804C5.61069 6.17947 6.10694 5.80724 6.29182 5.42521L7.6638 2.66284C8.41304 1.17391 9.61961 1.17391 10.3689 2.67264Z"
                          fill="white"
                        />
                      </svg>
                    </ListItemIcon>
                    <ListItemText primary={restaurant?.rating || 0} sx={{ my: 0 }} />
                  </ListItem>
                </Box>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ValidRewardCard
            onAddWishlist={handleAddWishlist}
            status={status}
            data={reward}
            sx={{ mb: 2 }}
            onRedeem={() => setRewardActionStatus('REDEEM')}
            onClaim={() => setRewardActionStatus('CLAIM')}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabIndex}>
              <TabList
                TabIndicatorProps={{
                  sx: {
                    height: '100%',
                    borderRadius: 1,
                    zIndex: 0,
                  },
                }}
                sx={{
                  p: 2,
                  bgcolor: '#E6E7EB',
                  borderRadius: 1,
                }}
                onChange={(_, value) => setTabIndex(value)}
              >
                <Tab
                  label="Information"
                  value="1"
                  sx={{
                    color: '#AAABB3',
                    zIndex: 10,
                    '&.Mui-selected': {
                      color: '#FFF',
                    },
                  }}
                />
                <Tab
                  label="Menu Items"
                  sx={{
                    color: '#AAABB3',
                    zIndex: 10,
                    '&.Mui-selected': {
                      color: '#FFF',
                    },
                  }}
                  value="2"
                />
                <Tab
                  label="Reviews"
                  value="3"
                  sx={{
                    color: '#AAABB3',
                    zIndex: 10,
                    '&.Mui-selected': {
                      color: '#FFF',
                    },
                  }}
                />
              </TabList>
              <TabPanel value="1">
                <Information data={restaurant} />
              </TabPanel>
              <TabPanel value="2">
                <Menus data={restaurant?.categories} />
              </TabPanel>
              <TabPanel value="3">
                <Reviews data={restaurant?.reviews} restaurant={restaurant} onUpdate={getRestaurant} />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Button variant="contained" onClick={() => navigate('/events/create', { state: restaurant })}>
          {'Create Event'}
        </Button>
      </Grid>
      <ConfirmDialog
        open={!!rewardActionStatus}
        onBack={rewardActionStatus === 'CLAIM' && handleAddWishlist}
        onConfirm={handleReward}
        onClose={() => setRewardActionStatus(null)}
        title={
          rewardActionStatus === 'CLAIM'
            ? `You need ${reward?.price} points to get this reward. you can buy it or add it to your wishlist.`
            : rewardActionStatus === 'REDEEM'
            ? 'Are you sure you want to        Redeem this event?'
            : ''
        }
        confirmText={rewardActionStatus === 'CLAIM' ? `Buy ${reward?.price} points` : rewardActionStatus === 'REDEEM' ? 'Redeem Reward' : ''}
        backText={rewardActionStatus === 'CLAIM' ? 'Add to wishlist' : 'Back'}
      />
    </Container>
  );
};
export default RewardDetail;
