import React from 'react';
import { Chip, Typography, Box } from '@mui/material';
import UserAvatar from './UserAvatar';
import PersonOutlinedIcon from '../Icon/PersonOutlinedIcon';

const GroupAvatar = ({ data, size, showLabel = true, ...props }) => {
  return (
    <Box sx={{ flexDirection: 'column', alignItems: 'center', display: 'flex' }}>
      <UserAvatar size={size} data={data} sx={{ boxShadow: '0px 4px 8px -2px rgba(30, 60, 120, 0.1), 0px 2px 4px -2px rgba(30, 60, 120, 0.06)' }} {...props} />
      <Chip
        label={data?.members?.length || 0}
        variant="outlined"
        icon={<PersonOutlinedIcon color="primary" sx={{ fontSize: '14px !important' }} />}
        color="primary"
        sx={{
          backgroundColor: 'background.surface',
          border: '1.5px solid #FFFFFF',
          boxShadow: ' 0px 1px 3px rgba(30, 60, 120, 0.1), 0px 1px 2px rgba(30, 60, 120, 0.06)',
          mt: -1,
          zIndex: 1,
        }}
        size="small"
      />
      {showLabel && <Typography align="center">{data.name}</Typography>}
    </Box>
  );
};

export default GroupAvatar;
