import React, { useState, useEffect } from 'react';
import {
  Button, Avatar, TextField, Typography, Container, Box, 
  IconButton, Stack, ListItemText, ListItem, ListItemIcon
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PointIcon from '../Icon/PointIcon';
import ExchangeIcon from '../Icon/ExchangeIcon';
import { useSelector, useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../Forms/StripePayment/CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_51MrM78C0MAs8CCaf6Rx9rw5bLmm0yY4pBHFP4FnnZtJKiUxyU35CljfAtXQAk6cGg2Jq6eAydf7fPMniBnuRxMAR00NJnKNUuU');

const PurChasePoints = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth?.auth);
  const purchasePoint = location?.state?.purchasePoint || null;
  const clientSecret = process.env.REACT_APP_STRIPE_PRIVATE_KEY || 'sk_test_51MrM78C0MAs8CCafWjnHEv7yOke5PD17oHIc4wHxFHVN0KmJwWu3oEuQTRSo2ANSKF0Jxhin6zBsaFnm0F05Abko005mAZaxxi'
  const options = {
    mode: 'setup',
    currency: 'usd',
  };

  const handelPurChasePoints = (link) => {
    navigate(link);
  };

  const handleBackPrevious = () => {
    navigate(-1);
  };

  return (
    // <Container>
    //   <Elements stripe={stripePromise} options={options}>
    //     <CheckoutForm
    //       price={purchasePoint.price}
    //       onPurChase={handelPurChasePoints}
    //     />
    //   </Elements>
    // </Container>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        textAlign: 'center',
        p: 3,
      }}
    >
      <Container
        sx={{
          bgcolor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: 2,
          p: 4,
          maxWidth: 600,
        }}
      >
        <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Coming Soon!
        </Typography>
        <Typography variant="h6" component="p" gutterBottom sx={{ mb: 4, color: 'text.secondary' }}>
          We are working hard to bring you this feature. Stay tuned!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleBackPrevious}
          sx={{ mt: 2 }}
        >
          Back
        </Button>
      </Container>
    </Box>
  );
};
export default PurChasePoints;
