import React, { useState, useEffect } from 'react';
import { Button, FormControl, FormLabel, Box, FormControlLabel, Typography, IconButton, Card, CardContent, CardActions } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserListItem from '../List/UserListItem';
import UserAvatar from '../Avatar/UserAvatar';
import { apiAddGroupMembers } from '../../../services/GroupService';
import { apiGetUsers } from '../../../services/UsersService';
import { find } from 'lodash';
import { getFullName } from '../../../utils/dataUtils';
import CancelIcon from '@mui/icons-material/Cancel';
import RoundedCheckbox from '../Checkbox/RoundedCheckbox';
const AddContact = ({ group, onSuccess }) => {
  const navigate = useNavigate;
  const auth = useSelector((state) => state.auth?.auth);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(group?.members || []);

  const handleSelectUser = (e, _user) => {
    e.target.checked ? setSelectedUsers([...selectedUsers, _user]) : setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== _user.id));
  };

  const handleAddContacts = async () => {
    const resGroupMembers = await apiAddGroupMembers(
      group.id,
      selectedUsers.map((_user) => _user.id)
    );
    if (resGroupMembers && !resGroupMembers.error) {
      onSuccess();
    }
  };

  const getUsers = async () => {
    const resUsers = await apiGetUsers();
    if (resUsers && !resUsers.error) {
      setUsers(resUsers.data);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Card>
      <CardContent>
        <FormControl>
          <FormLabel>{'Who would you like to add?'}</FormLabel>
          {users.map((user) => (
            <FormControlLabel
              control={<RoundedCheckbox name={user.id} color="success" checked={find(selectedUsers, (selectedUser) => selectedUser.id === user.id)} onChange={(e) => handleSelectUser(e, user)} />}
              label={<UserListItem data={user} />}
            />
          ))}
        </FormControl>
        <Box sx={{ display: 'flex' }}>
          {selectedUsers.map((_user) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                mr: 2,
              }}
            >
              <UserAvatar size="large" data={_user} />
              <IconButton
                size="small"
                onClick={() => setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== _user.id))}
                sx={{
                  mt: -2,
                  bgcolor: '#fff',
                  p: 0.2,
                  alignSelf: 'flex-end',
                }}
              >
                <CancelIcon color="error" />
              </IconButton>
              <Typography sx={{ fontSize: 10 }} color="text.secondary">
                {getFullName(_user)}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
      <CardActions>
        <Button variant="contained" disabled={!group.name} onClick={handleAddContacts}>
          {'Add Selected Contacts'}
        </Button>
      </CardActions>
    </Card>
  );
};
export default AddContact;
