import React from 'react';
import { SvgIcon } from '@mui/material';

const DocumentOutlinedIcon = ({ ...props }) => {
  return (
    <SvgIcon  viewBox="0 0 24 26" {...props}>
      <path
        d="M23.8333 10.8337V16.2503C23.8333 21.667 21.6666 23.8337 16.25 23.8337H9.74996C4.33329 23.8337 2.16663 21.667 2.16663 16.2503V9.75033C2.16663 4.33366 4.33329 2.16699 9.74996 2.16699H15.1666"
        stroke="currentcolor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.8333 10.8337H19.5C16.25 10.8337 15.1666 9.75032 15.1666 6.50033V2.16699L23.8333 10.8337Z"
        stroke="currentcolor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M7.58337 14.083H14.0834" stroke="currentcolor" fill="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.58337 18.417H11.9167" stroke="currentcolor" fill="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </SvgIcon>
  );
};
export default DocumentOutlinedIcon;
