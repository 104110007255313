import React, { useState, useEffect } from 'react';
import { Container, Avatar, Stack, ListItemSecondaryAction, Button, Box, Typography, ListItemText, IconButton, ListItem, ListItemAvatar } from '@mui/material';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { apiGetGroupById, apiGetGroupPreviousRestaurants, apiRemoveGroupMember, apiGetGroupRecommendedRestaurants } from '../../../services/GroupService';
import { getFullName, getInitialName } from '../../../utils/dataUtils';
import AddIcon from '@mui/icons-material/Add';
import GroupAvatar from '../Avatar/GroupAvatar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import RestaurantCard from '../Card/RestaurantCard';
import UserListItem from '../List/UserListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import AddContact from './AddContact';
import RestaurantsDialog from '../Dialog/RestaurantsDialog';
import UserAvatarGroup from '../Avatar/UserAvatarGroup';

const GroupView = () => {
  const navigate = useNavigate;
  const routeParams = useParams();
  const location = useLocation();
  const [group, setGroup] = useState(location.state || {});
  const [recommendedRestaurants, setRecommendedRestaurants] = useState([]);
  const [previousRestaurants, setPreviousRestaurants] = useState([]);
  const [viewIndex, setViewIndex] = useState();
  const [restaurantsDialogVisible, setRestaurantsDialogVisible] = useState(false);

  const getGroup = async () => {
    const resGroup = await apiGetGroupById(routeParams.id);
    if (resGroup && !resGroup.error) {
      setGroup(resGroup.data);
    }
  };

  const getRecommendedRestaurants = async () => {
    const resRestaurants = await apiGetGroupRecommendedRestaurants(group?.id, { range: [0, 3] });
    if (resRestaurants && !resRestaurants.error) {
      setRecommendedRestaurants(resRestaurants.data);
    }
  };
  const getPreviousRestaurants = async () => {
    const resRestaurants = await apiGetGroupPreviousRestaurants(group?.id, { range: [0, 3] });
    if (resRestaurants && !resRestaurants.error) {
      setPreviousRestaurants(resRestaurants.data);
    }
  };

  const handleDeleteMember = async (member) => {
    const resMember = await apiRemoveGroupMember(group.id, member.id);
    if (resMember && !resMember.error) {
      getGroup();
    }
  };

  useEffect(() => {
    getGroup();
  }, []);

  useEffect(() => {
    getPreviousRestaurants();
    getRecommendedRestaurants();
  }, [group?.id]);

  const renderView = () => {
    switch (viewIndex) {
      case 1:
        return (
          <Box>
            <Button variant="text" onClick={() => setViewIndex(0)}>
              {'Group View'}
            </Button>
            <AddContact group={group} onSuccess={() => setViewIndex(1)} />
          </Box>
        );
      case 2:
        return (
          <Box>
            <Button variant="text" onClick={() => setViewIndex(0)}>
              {'Group View'}
            </Button>
            <Typography color="primary.main" align="center">
              {'Others can join your group with the code below'}
              <Typography variant="h4" color="primary.main" align="center">
                {group?.qr_code}
              </Typography>
            </Typography>
            {group?.members?.length > 0 &&
              group?.members.map((member) => (
                <ListItem>
                  <ListItemText>
                    <UserListItem data={member} />
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => handleDeleteMember(member)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </Box>
        );
      default:
        return (
          <Box>
            <Button variant="text" sx={{ float: 'right' }} onClick={() => setViewIndex(2)}>
              {'Group Info'}
            </Button>
            <ListItem>
              <ListItemAvatar sx={{ mr: 2 }}>
                <GroupAvatar data={group} size="large" showLabel={false} />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ color: 'text.secondary', variant: 'h5' }}
                primary={getFullName(group)}
                secondary={
                  <Stack direction='row' justifyContent='flex-start' alignItems='center'>
                    <UserAvatarGroup size="small" data={group?.members}  max={3}></UserAvatarGroup>
                    <IconButton sx={{ ml: 2 }} onClick={() => setViewIndex(1)}>
                      <Avatar>
                        <AddIcon />
                      </Avatar>
                    </IconButton>
                  </Stack>
                }
              />
            </ListItem>
            {recommendedRestaurants.length > 0 && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>{'Recommended for our group'}</Typography>
                  <Button onClick={() => setRestaurantsDialogVisible(true)} variant="text">
                    {'View All'}
                  </Button>
                </Stack>
                <Swiper
                  className="mySwiper-3"
                  speed={1500}
                  slidesPerView={3}
                  spaceBetween={30}
                  loop={false}
                  autoplay={{
                    delay: 2000,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    360: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {recommendedRestaurants.map((data, index) => (
                    <SwiperSlide key={index}>
                      <RestaurantCard data={data} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            )}
            {previousRestaurants.length > 0 && (
              <>
                <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
                  <Typography>{'Previous Restaurant '}</Typography>
                  <Button variant="text">{'View All'}</Button>
                </Stack>
                <Swiper
                  className="mySwiper-3"
                  speed={1500}
                  slidesPerView={3}
                  spaceBetween={30}
                  loop={false}
                  autoplay={{
                    delay: 2000,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    360: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {previousRestaurants.map((data, index) => (
                    <SwiperSlide key={index}>
                      <RestaurantCard data={data} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            )}
            <RestaurantsDialog data={group} open={restaurantsDialogVisible} onClose={() => setRestaurantsDialogVisible(false)} />
          </Box>
        );
    }
  };

  return <Container>{renderView()}</Container>;
};
export default GroupView;
