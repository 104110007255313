import moment from 'moment';
import { get } from 'lodash';

export const DATE_TIME_FORMAT_1 = 'DD MMM | HH:MM';

export const getCurrentOpeningParams = (openingHours) => {
  try {
    const openingHoursObj = JSON.parse(openingHours);
    if (!openingHoursObj) {
      console.log('Failed to parse opening hours');
      return null;
    }
    const currentWeekday = moment().day();
    const openTime = get(openingHoursObj, `periods.${currentWeekday - 1}.open.time`)
    if (!openTime) {
      return null;
    }
    const openTimeObj = moment(get(openingHoursObj, `periods.${currentWeekday - 1}.open.time`), 'HHmm');
    const closeTimeObj = moment(get(openingHoursObj, `periods.${currentWeekday - 1}.close.time`), 'HHmm');
    const currentTimeObj = moment(moment().format('hh:mm A'), 'hh:mm A');
    const isOpening = currentTimeObj.isBetween(openTimeObj, closeTimeObj);
    const todayOpeningHours = `${openTimeObj.format('hh:mm A')} - ${closeTimeObj.format('hh:mm A')}`;

    return { is_opening: isOpening, opening_hours: todayOpeningHours };
  } catch {
    return {};
  }
};

export const formatDate = (dateStr, format = DATE_TIME_FORMAT_1) => {
  const momentObj = moment(dateStr);
  if (!momentObj.isValid) {
    return '';
  }
  return momentObj.format(format);
  
};
